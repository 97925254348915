@import "../App.scss";

#demo {
    &__container {
        position: absolute;
        background-color: rgba(7, 8, 23, 0.85);
        width: 100%;
        height: 100vh;
        left: 0;
        top: 0;
    }

    &__transaction_id {
        width: 219px;
        height: 25px;
        margin: 0 18px 32px -2px;
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.4px;
        text-align: left;
        color: $hyperblue;
    }

    &__link_redirect {
        margin: 0 18px 32px 10px;
        width: 20px;
        color: rgba(5, 5, 82, 0.6);
        text-decoration: none;
    }
}

#MFA {
    &__container {
        padding: 30px;
        height: 100%;
        display: grid;
        grid-template-rows: auto 10fr;
        gap: 30px;
    }

    &__image_container {
        display: flex;
        justify-content: center;

        >img {
            width: auto;
            height: auto;
            max-height: 50px;
        }
    }

    &__content_container {
        // Hacky way to center content
        margin-top: -80px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

#BU-selection-page {
  font-family: "Inter", sans-serif;
  background-image: url("../assests/images/login-bg.jpg");
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: auto;
  box-shadow: 0 0 20px 0 rgba(5, 5, 82, 0.04);
  padding-top: 100px;
  background-repeat: no-repeat;
  background-size: cover;

  &__spinner {
    min-height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__container {
    width: 350px;
    height: 490px;
  }

  &__BU-list {
    margin-top: 24px;
    padding: 0 16px 0 16px;
    overflow-y: auto;
    overflow-x: hidden;
    height: 288px;
    scrollbar-width: none;

    &:hover {
      scrollbar-width: thin;
      scrollbar-color: rgba(4, 4, 73, 0.409) rgba(4, 4, 73, 0);
    }

    &-container {
      border-radius: 10px 10px 0 0;
      border: 1px solid #0505521A;
      user-select: none;
      padding-top: 36px;
      background-color: #FFFFFF;
      max-height: 448px;
      overflow-y: hidden;
      box-shadow: 0px 0px 20px 0px #0505520A;
    }

    &-header {
      color: #050552CC;
      font-size: 20px;
      font-weight: 600;
      display: flex;
      justify-content: center;
    }
  }

  &__go-back-container &__BU-list {
    padding-bottom: 52px;
  }

  &__go-back-container {
    margin-bottom: 16px;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: #554EF1;
    cursor: pointer;
  }

  &__go-back-container:hover {
    text-decoration: underline;
  }

  &__email-container {
    user-select: none;
    background-color: #FFFFFF;
    padding: 12px 20px 12px 20px;
    border-radius: 0 0 10px 10px;
    border-width: 0px 1px 1px 1px;
    border-style: solid;
    border-color: #0505521A;
    color: #05055299;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;

    &-highlighted-text {
      color: #050552CC;
    }
  }

}

@mixin full_screen_center_children_container() {
    position: absolute;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
}

#internal_login {
    &__container {
        @include full_screen_center_children_container();
    }
}

#non_auth_routes_wrapper {
    &__container {
        @include full_screen_center_children_container();
    }
}

#generic_redirect {
    &__container {
        @include full_screen_center_children_container();
    }
}