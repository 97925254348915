@import "../../App.scss";

$common_select_text_color: hsla(240, 89%, 17%, 0.6);
$selectTextInputColor:rgba(5, 5, 82, 0.4);
$selectBorderColor: rgba(5, 5, 82, 0.2);
$selectActiveBorderColor: rgba(85, 78, 241, 0.5);
$toast_notifiication_background_color: #050552;

%common__filter__button {
    color: $hyperblue;
    text-transform: none;
    border: solid 0.5px $grey_border;
    height : 3em;
    background-color: $border_grey;
    font-family: $font_family;

    &:hover {
        color: $clickable_blue;

        path {
            fill: $clickable_blue;
        }
    }
}

%common_dropdown_menu {
    font-size: 12px;
    font-weight: normal;
    font-family: $font_family;
    color: $hyperblue;

    &:hover {
        background-color: $select_blue;
    }
}

#common__filterBy {

    &__container {
        height: 2.25em;
    }

    &__button {
        @extend %common__filter__button;
        height: 100%;
        margin-left : 1em;
    }

    &__button_text {
        font-weight: 600;
        font-size: 12px;
        margin: 0;
        padding: 1em 0;
    }
}

#common__refresh {

    &__container {
        margin-left: 0.5em;
    }

    &__button {
        @extend %common__filter__button;
        padding: 9px 0;
        float: right;
        height: fit-content;
        width: fit-content;
    }

    &__icon {
        flex-grow: 0;
        margin: 0;
    }
}

#common__appIdDropdown {

    &__container {
        margin-left: 1em;
        height : 2.25em;
    }

    &__button {
        @extend %common__filter__button;
        font-size: 12px;
        height: 100%;
        align-items: center;
    }

    &__button_text_container {
        display: flex;
    }

    &__vertical_line {
        width: 0.5px;
        height: 12px;
        margin: 2px 5.5px 1px 6px;
        background-color: $grey_border;
    }

    &__tooltiptext {
        width: 16.5em;
        background-color: $border_grey;
        border-radius: 6px;
        border: solid 0.5px $grey_border;
        font-size: 12px;
        font-family: $font_family;
        color: $hyperblue;
        text-align: center;
        padding: 1.25em;
        display: inline-flex;
        position: relative;
        z-index: 1;
        top: 5em;
        right: 1.5em;
    }

    &__appId_text {
        font-weight: 600;
        font-family:$font_family;
        color: $hyperblue;
        font-size: 12px;
        &:hover {
            color: $clickable_blue;
        }
    }

    &__production_text--disabled {
        font-weight: 600;
        font-family:$font_family;
        color: $grey_border;
        font-size: 12px;
    }
}

#common__timePeriodDropdown {
    &__container {
        margin-left: 1em;
        height : 2.25em;
    }

    &__button {
        @extend %common__filter__button;
        font-size: 12px;
        height: 100%;
        align-items: center;
    }

    &__timePeriod_text {
        font-weight: 600;
    }

    &__dropdown_menu {
        @extend %common_dropdown_menu;
    }

    &__selected_item {
        @extend %common_dropdown_menu;
        color: $clickable_blue;
    }
}

.common_workflow_select_input {
    width: 100%;
    margin-left: 20px;
    & .MuiOutlinedInput-input {
        color: $selectTextInputColor,
    }
    & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
        border-color: $selectBorderColor,
    }
    &:hover .MuiOutlinedInput-input {
        color: $selectTextInputColor,
    }
    &:hover .MuiInputLabel-root {
        color: $selectTextInputColor,
    }
    &:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
        border-color: $selectActiveBorderColor,
    }
    & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input {
        color: $selectTextInputColor,
    }
    & .MuiInputLabel-root.Mui-focused {
        color: $selectTextInputColor,
    }
    & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: $selectActiveBorderColor,
    }
}

.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
    margin-top: 2px;
    border-radius: 5px !important;
    box-shadow: 0 0 15px 0 rgba(239, 239, 239, 0.25) !important;
    border: solid 0.5px #dbdbdb !important;
}

.rdrDefinedRangesWrapper {
    width: 100% !important;
    white-space: nowrap;
    font-size: 14px !important;
}

.rdrStaticRange {
    font-family: $font_family;
    color: $hyperblue;
    font-weight: 400;
    font-size: 13px !important;
}

.rdrStaticRangeSelected {
    color: $clickable_blue !important;
    font-weight: 600;
    font-size: 13px !important;
}

.rdrDateDisplayWrapper {
    background-color: transparent !important;
}

.rdrDateDisplayItem {
    box-shadow: none !important;
    border-color: #dbdbdb !important;
}

.common__table_wrapper {
    >table {
        border-radius: 6px;
        >thead {
            background-color: rgba(5, 5, 82, 0.05) !important;
        }
        >tbody {
            >tr {
                &:not(:hover) {
                    background-color: #ffffff !important; 
                }
                >td {
                    padding: 16px;
                }
            }
        }
    }
}


.toast {
    &_container {
        position: fixed;
        bottom: 5%;
        width: 100%;
    }

    &_notification {
        background-color: $toast_notifiication_background_color;
        color: #fff;
        font-size: 14px;
        padding: 8px 12px;
        margin: auto;
        border-radius: 6px;
        display: flex;
        width: fit-content;
        align-items: center;
    
        &.false {
            display: none;
        }
        
        div {
            width: fit-content;
        }
    
        p {
            margin: 0;
            padding: 0;
        }
    }
}
