@import "../../App.scss";

#common_modal {
    &__container {
        color : $hyperblue;
        font-size: $subheading_font_size;
        font-weight: normal;
        font-family: $font_family;
        padding: 0 24px;
        width: 350px;
        height: 623px;
        border-radius: 6px;
    }

    &__heading {
        font-size: 20px;
        font-weight: bold;
        margin-top: 80px;
        margin-bottom: 24px;
    }
}

#landing_modal {
    &__ordered_list {
        margin-top: 40px;
    }

    &__list_item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    &__number_bullet {
        width: 24px;
        height: 24px;
        margin-right: 15px;
        object-fit: contain;
    }

    &__get_started {
        width: 100%;
        height: 48px;
        margin-top: 201px;
        margin-bottom: 24px;
        display: block;
        background-color: $clickable_blue;
    }

    &__get_started_arrow {
        height: 16px;
        width: 16px;
        margin-left: 4px;
        vertical-align: middle;
    }

    &__footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__footer_item {
        font-size: 10px;
        display: flex;
        align-items: center;
    }

    &__footer_language_icon {
        height: 16px;
        width: 16px;
        margin-right: 5px;
    }

    &__footer_powered_by_icon {
        margin-left: 35%;
        margin-right: 5px;
    }
}

#response_modal {
    &__body {
        display: flex;
        flex-direction: column;
        height: 65%;
        justify-content: space-between;
        align-items: center,
    }

    &__image_container {
        display: flex;
        justify-content: center;
    }

    &__bottom_box {
        padding: 16px 12px 31px 12px;
        border-radius: 10px;
        background-color: rgba(51, 90, 255, 0.1);
        font-size: 12px;
    }

    &__visiblity_text {
        font-size: 10px;
        color: #828ec0;
    }

    &__visiblity_icon {
        float: left;
        margin-right: 8px;
        font-weight: 300;
    }

    &__retry_link {
        text-decoration: none;
        color: $clickable_blue;
        font-weight: 600;
    }
    &__footer {
        font-size: 10px; 
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
