#application_details_collapsible {
  font-family: "Inter" !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  border-radius: 8px 8px 0 0;
  background-color: #FFFFFF;
  border: 1px solid rgba(5, 5, 82, 0.10);

  &:hover {
      background-color: #FAFAFC;
      box-shadow: 0px 2px 5px 0px rgba(5, 5, 82, 0.04);
      cursor: pointer;
  }

  &__left_column, &__right_column {
      margin: 0px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
  }

  &__right_column {
      margin-right: 6px;
      transform: rotate(270deg);
  }

  &__text {
      margin-left: 12px;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      color: #050552;
  }

  &__children {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 8px;
      border-right: 1px solid #DEDFEE;
      border-left: 1px solid #DEDFEE;
      border-bottom: 1px solid #DEDFEE;
      border-radius: 0 0 8px 8px;
      background-color: #F2F2F9;
  }

  .active {
      transform: rotate(360deg);
  }

  &__label {
      display: flex;
      margin-left: 10px;
  }
}

.border_radius_enabled {
  border-radius: 8px !important;
}

.border_radius_default {
  border-radius: 8px 8px 0 0 !important;
}


#reason_behind_status_container {
  box-shadow: none !important;

  &__auto_approved {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 24px 8px 32px 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    box-shadow: none !important;

    &__icon_section {
      display: flex;
      width: 100%;
      height: auto;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      > div {
        display: flex;
        padding: 12px 4px 12px 12px;
        align-items: center;
        gap: 8px;

        > img {
          width: 100%;
          height: 100%;
        }
      }
    }

    &__content_section {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 4px;
      justify-content: center;

      > span {
        color: rgba(5, 5, 82, 0.8);
        text-align: center;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
      }

      > p {
        color: rgba(5, 5, 82, 0.8);
        text-align: center;
        font-family: Inter;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        margin: 0;
      }
    }
  }

  &__user_cancelled {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 24px 8px 32px 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    box-shadow: none !important;

    &__icon_section {
      display: flex;
      width: 100%;
      height: auto;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      > div {
        display: flex;
        padding: 12px 4px 12px 12px;
        align-items: center;
        gap: 8px;

        > img {
          width: 100%;
          height: 100%;
        }
      }
    }

    &__content_section {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;

        > span {
          color: rgba(5, 5, 82, 0.8);
          text-align: center;

          /* Heading-Body 1 */
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px; /* 142.857% */
        }

        > p {
          margin: 0;
          color: rgba(5, 5, 82, 0.8);
          text-align: center;

          /* Body 2 */
          font-family: Inter;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px; /* 138.462% */
          align-self: stretch;
        }
      }
      > p {
        color: #554ef1;
        text-align: center;

        /* Body 2 - Medium */
        font-family: Inter;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 153.846% */
        margin: 0;

        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }
}
