@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

/* login page designs */

.onboard-container {
  padding: 36px 36px 32px;
  border: 1px solid rgba(5, 5, 82, 0.1);
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 20px 0 rgba(5, 5, 82, 0.04);
}

.onboard-container .recovery-mail-text {
  margin: 12px 0 0 0;
}

.sign-in-font {
  color: rgba(5, 5, 82, 0.8);
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  margin-top: 0;
  margin-bottom: 25px;
}

.display-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.hv-logo {
  height: 2em;
  width: 10em;
  margin-bottom: 25px;
}

#input-box {
  padding: 12px;
  border-radius: 6px;
  font-size: 13px;
  width: 280px;
  height: 40px;
  border: 1px solid rgba(5, 5, 82, 0.1);
  outline: none;
  cursor: pointer;
}

#input-box:focus {
  border: solid 1.3px #554ef1;
}

#input-box::placeholder {
  color: #9B9BBA;
}

#input-box:disabled {
  border: none;
  background-color: rgba(5, 5, 82, 0.03);
  cursor: not-allowed;
}

.margin-bottom {
  margin-bottom: 30px;
}

.margin-top{
  margin-top: 30px;
}

.margin-20 {
  margin: 20px 0;
}

.margin-top-20 {
  margin-top: 20px !important;
}

.forgot-password {
  font-size: 13px;
  color: rgba(5, 5, 82, 0.6);
  text-decoration: none;
  cursor: pointer;
}

.forgot-password:hover {
  text-decoration: underline;
}

.row-display {
  display: flex;
  flex-direction: row;
}

.column-display {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.forget-pass-font {
  color: rgba(5, 5, 82, 0.8);
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  margin: 0px;
}

.static-button {
  color: white;
  padding: 12px 20px;
  border-radius: 6px;
  background-color: rgba(85, 78, 241, 0.4);
  margin-top: 20px;
  height: 45px;
  width: 280px;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
}

.interactive-button {
  background-color: #554EF1;
}

.interactive-button:hover {
  background-color: #453FC3;
  cursor: pointer;
}

.invalid-field {
  border-radius: 4px;
  gap: 8px;
  font-size: 12px;
  color: #BF2600BF;
  font-weight: 500;
  margin-top: 16px;
  max-width: 280px;
}

.alert-icon {
  height: 20px;
  width: 20px;
}

.footer-text {
  width: 320px;
  font-size: 12px;
  color: rgba(5, 5, 82, 0.4);
  text-align: center;
}

.footer-text span {
  color: rgba(85, 78, 241, 0.8);
}

/* Reset password designs */
.onboard-page {
  font-family: "Inter", sans-serif;
  background-image: url("../../assests/images/login-bg.jpg");
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  height: 100%;
  width: auto;
  box-shadow: 0 0 20px 0 rgba(5, 5, 82, 0.04);
  background-repeat: no-repeat;
  background-size: cover;
}

.forget-pass-email-input {
  margin: 40px 0 20px 0;
}

.onboard-container p{
  font-size: 13px;
  width: 280px;
  color: rgba(5, 5, 82, 0.8);
  text-align: center;
  line-height: 20px;
}

.footer-text-reset-password {
  font-size: 13px;
  text-decoration: none;
  display: grid;
  text-align: center;
  color: rgba(5, 5, 82, 0.6)  ;
  margin-top: 20px;
  cursor: pointer;
}

.footer-text-reset-password:hover {
  text-decoration: underline !important;
}

.column-display .rocket-icon {
  height: 80px;
  margin: 25px 0;
}

.column-display .mfa-rocket-icon {
  height: 100px;
  margin-top: 12px;
}

.email-text {
  text-align: center;
  font-weight: 600;
  font-size: 13px;
  margin: 0 !important;
  word-wrap: break-word;
}

.onboard-page .footer-text {
  color: rgba(5, 5, 82, 0.6);
}

.password-modal {
  padding: 12px 16px;
  border-radius: 8px;
  background-color: #01011c;
  position: absolute;
  font-family: "Inter", sans-serif;
  left: 61vw;
  margin-top: 10px;
}

.top-440px {
  top: 440px;
}

.password-modal .row-display {
  gap: 10px;
  >img {
    height: 16px;
  }
}

.password-modal .row-display .password-modal-font, .password-modal p {
  color: white;
  margin: 6px 0;
  font-size: 12px;
}

.password-modal-font {
  text-align: left !important;
  line-height: 16px !important;
}

p.password-modal-font.invalid-text{
  color: rgba(255, 255, 255, 0.8)  !important;
}

.password-modal h2 {
  font-size: 12px ;
  font-weight: 600;
  color: #ffffff;
}

.relative-position {
  position: relative;
}

.eye-icon {
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 55%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
}

.row-display {
  display: flex;
  align-items: center;
}

.horizontal-line {
  flex-grow: 1;
  border: 0;
  height: 1px;
  background-color: rgba(5, 5, 82, 0.1); 
}

.or-text {
  margin: 0 13px;
  font-size: 11px;
  color: rgba(5, 5, 82, 0.4);
}

.back-to-login-btn {
  font-size: 13px;
  color: #05055299;
  background-color: transparent;
  margin-top: 20px;
  text-align: center;
  cursor: pointer;
  >button {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}

.left-text-align {
  text-align: left !important;
}

.resend-email span, .resend-email:disabled {
  font-size: 12px;
  color: rgba(5, 5, 82, 0.6);
  cursor: default;
}

.resend-email {
  cursor: pointer;
  margin-top: 8px; 
  margin-bottom: 20px;                                                
  color: #554ef1;
  font-size: 12px;
  font-weight: 500;
}

@keyframes shake {
  0%, 100% { transform: translateX(0); }
  10%, 50% { transform: translateX(-5px); }
  30%, 80% { transform: translateX(5px); }
}

.shake-container {
  animation: shake 0.5s;
}