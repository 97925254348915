// Plyr SCSS customizations

:root {
  --plyr-color-main: #000342; // Dark blue theme for progress bars, buttons, etc.
  --plyr-focus-visible-color: #ff9800; // Orange for focus states

  // Control styles
  --plyr-control-icon-size: 18px; // Adjusted icon size
  --plyr-control-spacing: 12px; // Increased spacing between controls
  --plyr-control-padding: 6px; // Smaller padding for controls
  --plyr-control-radius: 4px; // Rounded control buttons

  // Video control bar styles
  --plyr-video-controls-background: #000342; // Dark control bar
  --plyr-video-control-color: #ffffff; // White icons
  --plyr-video-control-color-hover: #ffffff; // Keep hover color consistent
  --plyr-video-control-background-hover: rgba(
    255,
    255,
    255,
    0.1
  ); // Subtle background hover color

  // Menu styles
  --plyr-menu-background: rgba(
    0,
    0,
    50,
    0.95
  ); // Custom blue background for menu
  --plyr-menu-color: #ffffff; // White text color in menu
  --plyr-menu-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); // Adjusted shadow for depth
  --plyr-menu-radius: 6px; // Slightly rounded menu corners
  --plyr-menu-item-arrow-color: #ffffff; // White arrows in the menu
  --plyr-menu-item-arrow-size: 8px; // Adjust arrow size

  // Progress bar styles
  --plyr-range-track-height: 1px; // Thinner seek bar
  --plyr-range-thumb-height: 14px; // Moderate thumb size for progress bar
  --plyr-range-thumb-background: #ffffff; // White progress ball
  --plyr-range-thumb-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); // Shadow for the thumb
  --plyr-range-thumb-active-shadow-width: 6px; // Active thumb shadow width
  --plyr-range-fill-background: #ffffff; // White seek bar fill
  --plyr-video-progress-buffered-background: rgba(
    255,
    255,
    255,
    0.2
  ); // Buffered progress

  // Captions styles
  --plyr-captions-background: rgba(
    0,
    0,
    0,
    0.75
  ); // Black background for captions
  --plyr-captions-text-color: #ffffff; // White text for captions

  // Tooltip styles
  --plyr-tooltip-background: rgba(0, 0, 0, 0.85); // Darker tooltip background
  --plyr-tooltip-color: #ffffff; // White tooltip text
  --plyr-tooltip-radius: 4px; // Rounded tooltip corners
  --plyr-tooltip-shadow: 0 2px 4px rgba(0, 0, 0, 0.15); // Subtle tooltip shadow

  // Font family
  --plyr-font-family: "Inter", sans-serif; // Use Inter font
}

// Additional styles for Plyr
.plyr {
  font-family: "Inter", sans-serif;
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 8px;

  &__control {
    transition: transform 0.2s ease;

    &:hover {
      transform: scale(1.1); // Slightly enlarge controls on hover
    }
  }

  &__menu {
    ul {
      li {
        padding: 8px 14px; // Consistent padding for menu items

        &:hover {
          background-color: rgba(255, 255, 255, 0.1); // Highlight effect
        }
      }
    }
  }

  &__progress {
    &__buffer {
      background: var(--plyr-video-progress-buffered-background);
    }

    &__fill {
      background: var(--plyr-range-fill-background); // White seek bar fill
      height: var(--plyr-range-track-height); // Set seek bar height
    }

    &__thumb {
      background: var(--plyr-range-thumb-background); // White progress ball
      height: var(--plyr-range-thumb-height); // Adjust thumb size
      box-shadow: var(--plyr-range-thumb-shadow); // Add shadow to thumb
    }
  }

  &__controls {
    display: flex;
    padding: 4px 12px !important;
    flex-shrink: 0;
    height: 44px;

    &__item {
      padding: 5.75px 4.667px 5.333px 4.75px !important;
    }
  }

  &__menu__container {
    display: flex;
    padding: 4px;
    background: #01011c;
  }

  &__volume {
    > input[type="range"] {
      min-width: 50px;
      max-width: 50px;
    }
  }
}
