@import "../../../App.scss";

$audit_button_bg_color: white;
$audit_button_border_color: rgba(5, 5, 82, 0.1);
$audit_button_box_shadow_color: rgba(85, 78, 241, 0.1);
$audit_button_hover_border_color: rgba(85, 78, 241, 0.1);
$audit_button_active_border_color: #554ef1;
$audit_button_text_color: rgba(5, 5, 82, 0.8);
$audit_button_active_text_color: #554ef1;
$audit_button_icon_color: rgba(5, 5, 82, 0.8);
$audit_button_active_icon_color: #554ef1;

@mixin common_button() {
    @include sub_heading();
    background-color: $audit_button_bg_color;
    border: 1px solid $audit_button_border_color;
    color: $audit_button_text_color;
    padding: 10px 12px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    white-space: nowrap;

    >.icon_right {
        margin-left: 8px;
    }

    >.icon_left {
        margin-right: 8px;
    }

    >svg {
        width: 15px;
        height: 15px;
        >* {
            fill: $audit_button_icon_color;
        }
    }

    &:hover:not(.disabled), &.active, &.selected {
        cursor: pointer;
        box-shadow: 0 0 15px 0 $audit_button_box_shadow_color;
        border: solid 1px $audit_button_hover_border_color;
        color: $audit_button_active_text_color;

        >svg {
            >* {
                fill: $audit_button_active_icon_color;
            }
        }
    }

    &.selected {
        border: solid 1px #554ef1;
    }

    &.disabled {
        cursor: not-allowed;
        opacity: 0.4;
    }
}

@mixin white_theme_button() {
    @include common_button();
}

@mixin purple_theme_button() {
    @include common_button();
    background-color: #554ef1;
    color: white;
    >svg {
        >* {
            fill: white;
        }
    }
    &:hover:not(.disabled) {
        color: white; 
        >svg {
            >* {
                fill: white;
            }
        }
    }
}

.custom_button {
    &.white {
        @include white_theme_button()
    }
    &.purple {
        @include purple_theme_button() 
    }
}

.custom-button-container {
    >.hover-text {
        position: absolute;
        padding: 10px;
        width: 150px !important;
        right: 31%;
        top: 31%;
        padding: 8px !important;
        border-radius: 7px;
        background-color: rgb(122, 123, 123);
    }

    >.hide-text {
        opacity: 0;
    }

    >.show-text {
        opacity: 1;
        color: white !important;
    }

    >.custom-loader-button {
        padding: 14px 52px !important;
        width: 280px !important;
        border-radius: 6px;
        border: solid 1px rgba(5, 5, 82, 0.1);
        background-color: #ffffff;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;
        cursor: pointer;

        >.disabled {
            cursor: not-allowed;
            opacity: 0.5;
        }

        >img {
            margin-right: 0px !important;
        }

        &:hover {
            box-shadow: none !important;
            background-color: #F8F8FA;
        }

        &:disabled {
            background-color: #F8F8FA !important;
            border: solid 1px rgba(5, 5, 82, 0.1) !important;
        }

        .common_spinner {
            border: 1.5px solid black !important;
            border-top-color: white !important;
            height: 20px !important;
            width: 20px !important;
        }

        .custom-loader-button-font {
            font-family: "Inter", sans-serif;
            font-size: 13px;
            font-weight: 500;
            text-align: left;
            color: rgba(5, 5, 82, 0.8);
          }
    }
}

.loading-button {
    background-color: #554EF1;

    .common_spinner {
        border: 1.5px solid #cdc7c7 !important;
        border-top-color: white !important;
        height: 20px !important;
        width: 20px !important;
    }
}