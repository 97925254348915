.accordion {
  &__container {
    font-size: 14px !important;
    font-family: "Inter" !important;
    color: rgba(5, 5, 82, 0.8);
    border: 0.5px solid rgba(5, 5, 82, 0.15);
    border-radius: 8px;
  }

  &__button {
    transition: 0.2s ease;
    width: 100%;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    border: none;
    border-bottom: 0.5px solid rgba(23, 23, 167, 0.05);
    color: rgba(5, 5, 82, 0.8);
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    border-radius: 8px;
    background: #fff;
    position: relative;
    cursor: pointer;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 97%;
      height: 1px;
      background: rgba(5, 5, 82, 0.1);
      display: none;
    }

    &:hover {
      background-color: rgba(5, 5, 82, 0.05);
    }

    &.button_show {
      font-weight: 600;
      border-radius: 8px 8px 0 0;

      &::after {
        display: block;
      }
      &:hover {
        cursor: pointer !important;
        background-color: #fff !important;
      }
    }

    img {
      transition: transform 0.3s ease-in-out;
      margin-left: 7px;
      width: 5px;

      &.open {
        transform: rotate(90deg);
      }
    }
  }

  &__content_container {
    overflow: hidden;
    transition: max-height 0.4s ease-in-out;
    max-height: 0;
    border-radius: 0 0 8px 8px;
    background: #fff;

    &.show {
      max-height: 500px; // Adjust if needed
    }
  }

  &__inner_content {
    padding: 16px;
    font-size: 13px !important;
    font-family: Inter, sans-serif;
    font-weight: 400;
    line-height: 18px;
    color: #050552cc;
    text-align: left;

    > div {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      gap: 12px;

      > img {
        width: 100%;
        margin-bottom: 10px;
      }

      > p {
        font-size: 14px;
        margin: 0;
        color: rgba(5, 5, 82, 0.8);
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;

        > a {
          font-size: 14px;
          margin: 0;
          color: #554ef1;
          font-family: Inter, sans-serif;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  &__data_div {
    width: 100%;
    height: auto;

    > img {
      width: 100%;
      margin-bottom: 10px;
    }

    > p {
      font-size: 14px;
      margin: 0;
      margin-top: 12px;
      color: rgba(5, 5, 82, 0.8);
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }

  &__fade-in {
    opacity: 0;
    animation: fadeIn 1s ease-in-out forwards;

    @keyframes fadeIn {
      from {
        opacity: 0;
        transform: translateY(10px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  @media (max-width: 768px) {
    &__button {
      font-size: 14px;
    }
  }

  .count_text {
    color: rgba(5, 5, 82, 0.8);
    font-size: 15px !important;
    font-weight: 600;
  }

  .arrow {
    transition: transform 0.3s ease-in-out;
    margin-right: 7px;
    color: rgb(40, 35, 142);
    font-size: 13px;
    font-weight: 200;
  }

  .open {
    transform: rotate(90deg);
  }
}
