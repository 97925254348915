@import "../../App.scss";

$details_table_conf_background_color: rgba(255, 225, 155, 0.35);
$details_table_conf_text_color: #9a4c24;
$details_header_color: rgba(5, 5, 82);
$details_tab_color: rgba(5, 5, 82, 0.6);
$details_tab_active_color: #554ef1;
$details_table_border: 0.5px solid rgba(5, 5, 82, 0.1);
$details_table_cell_color: rgba(5, 5, 82, 0.8);

@mixin common_status_styles {
  font-size: 12px;
  font-weight: bold;
  margin: 2px;
  padding: 0.5em 1.75em;
  border-radius: 20px;
}
$aml_table_background_colour: rgba(5, 5, 82, 0.02);
$details_header_color: rgba(5, 5, 82);
$details_tab_color: rgba(5, 5, 82, 0.6);
$details_tab_active_color: #554ef1;
$details_table_border: 0.5px solid rgba(5, 5, 82, 0.1);
$details_table_cell_color: rgba(5, 5, 82, 0.8);

@mixin common_status_styles {
  font-size: 12px;
  font-weight: bold;
  margin: 2px;
  padding: 0.5em 1.75em;
  border-radius: 20px;
}

@mixin table_conf_span {
  display: inline;
  border-radius: 20px;
  background-color: $details_table_conf_background_color;
  padding: 6px 12px;
  margin-left: 12px;
  color: $details_table_conf_text_color;
  font-size: 9px;
  font-weight: 500;
  letter-spacing: 0.08px;
  white-space: nowrap;
}
.details {
  &__status_icon {
    width: 8px;
    height: 8px;
  }
  &__status_icon_redirect {
    width: 16px;
    height: 16px;
  }
  }
#tab {
  &__summary_tab {
    width: 63.125em;
    height: 3em;
    margin: 0.3125em 1em 1em 0.625em;
    padding: 0.9375em 37.3125em 0.9375em 1.5em;
    border-radius: 0.3125em;
    border: solid 0.5px rgba(85, 78, 241, 0.1);
    background-color: #ffffff;
  }
  &__summary {
    width: auto;
    height: 1.125em;
    margin: 0 0.3125em 1.25em 0;
    font-size: $subheading_font_size;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-transform: none !important;
    text-align: left;
    color: rgba(5, 5, 82, 0.6);
  }
  &__deep_dive {
    button {
      font-size: 12px !important;
      color: rgba(5, 5, 82, 0.6) !important;
      margin: 0 0.3125em 1.25em 0.5em;
      font-weight: 400 !important;
      margin-right: 30px !important;
      text-transform: none !important;
      padding: 0 !important;
    }
    button:focus {
      color: #554ef1 !important;
    }
  }
  &__audit_trail {
    width: 6em;
    height: 1.125em;
    margin: 0 0 1.25em 4em;
    font-size: $subheading_font_size;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-transform: none !important;
    text-align: left;
    color: rgba(5, 5, 82, 0.6);
  }
}

#audit {
  &__button__approve {
    width: 8.5em;
    height: 3.3em;
    flex-grow: 0;
    padding: 20px 21px;
    border-radius: 6px;
    border: solid 0.5px #554ef1;
    background-color: #554ef1;
    color: white !important;
    font-weight: 300 !important;
    text-transform: none !important;
    border-radius: 5px !important;
    font-size: 14px !important;
  }
  &__button__end {
    margin: 0;
    padding: 1px 5px;
    font-size: 10px !important;
    font-weight: 600 !important;
    background-color: #a19df8 !important;
    border-radius: 6px;
  }
  &__button__end__reject {
    margin: 0;
    padding: 1px 5px;
    font-size: 10px !important;
    font-weight: 600 !important;
    background-color: #f79390 !important;
    border-radius: 6px;
  }
  &__button__reject {
    width: 8.5em;
    height: 3.3em;
    flex-grow: 0;
    margin-right: 15px;
    padding: 20px 25px 20px 26px;
    border-radius: 6px;
    border: solid 0.5px #dd4a46;
    background-color: #fff4f4;
    color: #dd4a46 !important;
    font-weight: 300 !important;
    text-transform: none !important;
    border-radius: 5px !important;
    font-size: 14px !important;
  }
  &__button_next {
    width: 1.9375em;
    height: 1.125em;
    margin: -36.8em 0 1em 86em;
    font-size: $subheading_font_size;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    text-transform: none !important;
    color: rgba(5, 5, 82, 0.6);
  }
  &__button_prev {
    width: 6.9375em;
    height: 1.125em;
    margin: -34.2em 0 1em 78.7em;
    font-size: $subheading_font_size;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    text-transform: none !important;
    color: rgba(5, 5, 82, 0.6);
  }
}

#module {
  &__modules_rectangle {
    min-height: 16.8125em;
    padding: 28px;
    border-radius: 6px;
    border: solid 0.5px rgba(85, 78, 241, 0.1);
    background-color: #ffffff;
  }
  &__modules_used_text {
    width: 150px;
    height: 14px;
    margin: 5px 77px 30px 5px;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.6px;
    text-align: left;
    color: rgba(5, 5, 82, 0.4);
  }
  &__modules_list {
    margin: 20px 0px 0px 0px;
  }
  &__modules_list_item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0px 0px 13px 0px;
    > p,
    button {
      margin-bottom: 0 !important;
    }
  }
  &__ellipse_red {
    width: 10px;
    height: 10px;
    border: solid 2px #dd4a46;
    border-radius: 50%;
    background-color: #ffffff;
  }
  &__ellipse_yellow {
    width: 10px;
    height: 10px;
    border: solid 2px #dd4a46;
    border-radius: 50%;
    background-color: #ffffff;
  }

  &__selfie_verification_text {
    background: unset !important;
    outline: unset !important;
    border: unset !important;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-transform: none !important;
    text-align: left;
    color: rgba(5, 5, 82, 0.8);
  }
}

#details {
  &__container {
    background-color: $border_grey;
    border-radius: 6px;
    border: solid 0.5px rgba(5, 5, 82, 0.1);
    color: $hyperblue;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__header {
    &__container {
      margin: 0px;
      padding: 16px !important;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }

    &__title {
      margin-left: 12px;
      @include heading_inside_modal_bold();
      color: #050552;
    }

    &__image {
      width: 35px;
      height: 35px;
      background-color: #f6f7ff;
      border-radius: 3px;
      background-color: rgba(85, 78, 241, 0.05);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      > svg {
        color: rgba(5, 5, 82, 0.8);
        height: 16px;
        width: 16px;
      }
    }
  }

  &__body {
    &_tabs {
      min-height: unset;
      border-bottom: 0.5px solid #ebeef1;
      padding: 0 16px !important;
      button {
        @include secondary_text();
        color: $details_tab_color !important;
        margin-right: 30px !important;
        text-transform: none !important;
        padding: 0px;
        min-height: unset;
        padding-bottom: 9px;
      }
      button.tabActive {
        color: $details_tab_active_color !important;
        font-weight: 600 !important;
      }
      span.MuiTabs-indicator {
        background-color: $details_tab_active_color !important;
        height: 1px !important;
      }
    }
  }
  &__tab {
    &__video {
      &__container {
        padding-top: 16px;
      }
      &__error {
        color: red;
        font-size: 14px;
      }
    }
    &__container {
      padding: 16px;
      display: flex;
      flex-direction: column;
      overflow-x: auto;
    }
    &__header {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 32px;
      flex-wrap: wrap;
      gap: 24px 24px;
      &__status {
        &__approved {
          @include common_status_styles();
          background-color: #e3fcef;
          color: #006644 !important;
        }

        &__declined {
          @include common_status_styles();
          background-color: #ffebe6;
          color: #bf2600 !important;
        }

        &__review {
          @include common_status_styles();
          background-color: #ffe19b59;
          color: #9a4c24 !important;
        }

        &__dropped {
          @include common_status_styles();
          background-color: #f4f5f7;
          color: rgba(5, 5, 82, 0.6) !important;
        }

        &__applying {
          @include common_status_styles();
          background-color: #deebff;
          color: #0747a6 !important;
        }

        &__reported {
          @include common_status_styles();
          background-color: #deebff;
          color: #0747a6 !important;
        }
      }

      p {
        @include base_font_and_inputs();
        margin: 0px;
        color: rgba(5, 5, 82, 0.6);
        white-space: nowrap;
        span {
          color: rgba(5, 5, 82, 0.6);
          margin-left: 7px;
          font-weight: 500;
        }
        button:focus {
          color: #554ef1 !important;
        }
      }
    }
    &__details {
      &__container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;

        h1 {
          @include overline_heading();
          color: rgba(5, 5, 82, 0.4);
          margin: 0px;
          margin-bottom: 16px;
          white-space: nowrap;
        }
        h2 {
          @include secondary_text();
          text-align: left;
          color: rgba(5, 5, 82, 0.8);
          margin: 0px;
          margin-bottom: 12px;
          white-space: nowrap;
        }
      }
      &__image {
        &__container {
          flex: 0 1;
          margin-right: 48px;
          p {
            @include base_font_and_inputs();
            color: black;
            margin: 0px 35px 0px 0px;
            color: rgba(5, 5, 82, 0.6);
            margin-bottom: 16px;
            span {
              font-weight: 500;
              margin-left: 7px;
              color: rgba(5, 5, 82, 0.6);
            }
          }
        }
        margin: 0px;
        margin-bottom: 24px;
      }
      &__hitsfound {
        width: 62px;
        height: 14px;
        margin: 30px 5px 20px 2px;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        // font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: rgba(5, 5, 82, 0.8);
      }
      &__verticalLine {
        width: 0.5px;
        height: 24px;
        margin: -17px 5.5px 19px 85px;
        transform: rotate(360deg) !important;
        background-color: rgba(5, 5, 82, 0.2);
      }
      &__amlimage {
        &__container {
          flex: 0.5 1;
          margin-right: 10px;
          font-family: $font_inter;
          p {
            @include base_font_and_inputs();
            color: black;
            margin: 0px 35px 0px 0px;
            color: rgba(5, 5, 82, 0.8);
            margin-bottom: 20px;
            span {
              color: rgba(5, 5, 82, 0.6);
              margin-left: 7px;
            }
          }
          h1 {
            margin: 13px 35px 0px 70px;
            margin-bottom: 20px;
            span {
              color: rgba(5, 5, 82, 0.6);
              margin-left: 20px;
            }
          }
        }
        margin: -17px 40px;
      }
      &__aml_panel {
        width: 513px;
        height: 100vh;
        font-family: $font_inter;
        overflow-y: scroll;
        right: 0;
        padding: 20px;
        box-shadow: 0 0 15px 0 rgba(85, 78, 241, 0.1);
        border: solid 0.5px rgba(5, 5, 82, 0.1);
        position: fixed;
        background-color: #ffffff;
        z-index: 1001;
        top: 0;
      }
      &__aml_panel_inner_div {
        display: flex;
        flex-direction: column;
      }
      &__aml_panel_div {
        position: relative;
      }
      &__aml_transactionId {
        font-family: $font_inter;
        width: 335px;
        height: 26px;
        margin: 20px 0px;
        font-size: 22px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.44px;
        text-align: left;
        color: #050552;
      }
      &__aml_date {
        @include sub_heading();
        width: 100px;
        height: 5px;
        margin: 0px 5px 0 0px;
        text-align: left;
        color: rgba(5, 5, 82, 0.8);
      }
      &__aml_close {
        width: 24px;
        height: 24px;
        margin-left: auto;
        // margin: 1px 24px 24px 250px;
        object-fit: contain;
      }
      &__body {
        flex: 1 0;
        width: 100%;
        &__table_container {
          border: $details_table_border;
          border-radius: 6px;
          max-height: 500px;
          overflow: auto;
          td {
            @include secondary_text();
            padding: 20px;
            color: rgba(5, 5, 82, 0.6);
            > span.conf {
              display: none;
            }
          }
        }
        &__tr {
          &:hover {
            cursor: pointer !important;
          }
        }
        &__tabs {
          border-bottom: 0.5px solid #ebeef1;
          // margin-left: -180px !important;
          button {
            @include secondary_text();
            color: rgba(5, 5, 82, 0.6) !important;
            margin-right: 30px !important;
            text-transform: none !important;
            padding: 0 !important;
          }
          button.tabActive {
            color: #554ef1 !important;
          }
        }
        &__amltable_container {
          border: 0.7px solid #ebebeb;
          border-radius: 6px;
          margin: -5px 0 0 -3px;
          box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.2);
          max-height: 500px;
          overflow: auto;
          max-width: auto !important;

          &__t {
            @include secondary_text();
            padding: 20px;
            color: #050552 !important;
            max-width: 25%;
          }
          &__dob {
            color: rgba(5, 5, 82, 0.6) !important;
          }
          &__tableHeading {
            padding: -100px;
            @include secondary_text();
            font-weight: 600 !important;
            background-color: #f6f7ff;
            color: #050552 !important;
            padding-right: -100px !important;
          }
          td:nth-child(3) {
            // white-space: nowrap;
            min-width: 150px;
            overflow-y: hidden;
            padding-right: 20px !important;
          }
          td:nth-child(1) {
            // white-space: nowrap;
            min-width: 170px;
            overflow-y: hidden;
            padding-right: 50px;
          }
          td:nth-child(2) {
            //white-space: nowrap;
            min-width: 200px;
            overflow-y: hidden;
            padding-right: 100px;
          }
          td:nth-child(4) {
            // white-space: nowrap;
            min-width: 200px !important;
            overflow-y: hidden;
            padding-right: 50px;
          }
        }
        &__amlDetail_container {
          border: 0.7px solid #ebebeb;
          border-radius: 6px;
          box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.2);
          max-height: auto;
          margin: 18px 0px;
          overflow: auto;
          width: 100%;
          &__table {
            width: 100%;
            border-collapse: collapse;
          }
          &__tr {
            border-bottom: 1px solid rgba(5, 5, 82, 0.1);
          }
          &__t {
            padding: 20px;
            @include secondary_text();
            color: #050552;
            padding-right: 0px !important;
          }
          &__tableHeading {
            padding: 10px;
            @include secondary_text();
            white-space: nowrap;
            color: #050552 !important;
            padding-right: 129.5px !important;
            background-color: $aml_table_background_colour !important;
          }
          &__tableHeadingSources {
            padding: 10px;
            @include secondary_text();
            white-space: nowrap;
            color: #554ef1 !important;
            padding-right: 90px !important;
            background-color: $aml_table_background_colour !important;
          }
        }

        h2 {
          @include secondary_text();
          font-weight: 600;
          color: rgba(5, 5, 82, 0.8);
          margin: 12px 0px !important;
        }
        table {
          border-collapse: collapse;
          width: 100%;
          overflow: auto;
        }
        td {
          @include secondary_text();
          padding: 12px;
          color: $details_table_cell_color;
          min-width: 100px;
          > span.conf {
            display: none;
          }
        }
        td.error {
          font-weight: normal;
          text-transform: none;
          color: #373775;
        }
        td.left.normal {
          font-weight: normal;
          text-transform: capitalize;
        }
        td.left.aml {
          font-weight: normal;
          text-transform: capitalize;
          color: #050552 !important;
        }
        td.left.warning {
          font-weight: normal;
          text-transform: capitalize;
          color: #9a4c24;
        }
        td.left.alert {
          font-weight: normal;
          text-transform: capitalize;
          color: #dd4a46;
        }
        td.right {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          font-weight: 500;
          min-width: 150px;
        }
        td.right.normal {
          text-align: right;
          word-break: break-word;
        }
        td.right.aml {
          font-weight: 500;
          text-align: right;
          color: #050552 !important;
        }
        td.right.amldetails {
          font-weight: 100;
          text-align: right;
          color: #050552 !important;
        }
        td.right.warning {
          text-align: right;
          color: #9a4c24;
          > span.conf {
            @include table_conf_span();
          }
        }
        td.right.alert {
          text-align: right;
          color: #dd4a46;
        }
        td.err {
          text-align: right;
          color: #dd4a46;
        }
        tr:nth-child(even) {
          background-color: $aml_table_background_colour !important;
        }
      }
    }
  }

  &__empty {
    &_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 289px;
      gap: 10px;
    }

    &_info {
      text-align: center;
      > p {
        margin: 0;
        padding: 0;
      }

      &_main {
        @include modal_and_drawer_header();
        line-height: 24px;
        color: rgba(5, 5, 82, 0.6);
        letter-spacing: 0.02em;
      }

      &_sub {
        @include base_font_and_inputs();
        color: rgba(5, 5, 82, 0.6);
        margin-top: 5px !important;
      }
    }
  }
}

#video {
  &_player {
    height: 585px;
    margin: auto 24px auto 24px;
    border-radius: 4.6px;
    border: solid 1px rgba(5, 5, 82, 0.1);
    transition: 0.1s ease;
    &:hover {
      cursor: pointer;
    }
  }
  &_details {
    &__header {
      &__container {
        margin: 0px;
        margin-bottom: 16px;
        p {
          margin: 0px;
          font-size: $subheading_font_size;
          font-weight: bold;
          color: $details_header_color;
        }
      }
    }
    &__body {
      &_tabs {
        min-height: unset;
        border-bottom: 0.5px solid #ebeef1;
        button {
          font-size: 12px !important;
          color: $details_tab_color !important;
          font-weight: 400 !important;
          margin-right: 30px !important;
          text-transform: none !important;
          padding: 0px;
          min-height: unset;
          padding-bottom: 9px;
        }
        button.tabActive {
          color: $details_tab_active_color !important;
          font-weight: 600 !important;
        }
        span.MuiTabs-indicator {
          background-color: $details_tab_active_color !important;
          height: 1px !important;
        }
      }
    }
    &__container {
      background-color: $border_grey;
      border-radius: 6px;
      border: solid 0.5px rgba(5, 5, 82, 0.1);
      color: $hyperblue;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-top: 0;
      margin-bottom: 16px;
      &__section {
        padding: 0 16px 16px 16px;
      }
    }
    &_row {
      display: flex;
    }
    &_col {
      flex: 1;
      padding: 8px;
      &_title {
        @include overline_heading();
        text-align: left;
        color: rgba(5, 5, 82, 0.4);
      }
      &_timeline {
        height: 727px;
        overflow-y: scroll;
        &_icon {
          padding: 6px;
          border-radius: 3px;
          background-color: rgba(5, 5, 82, 0.05);
          &_text {
            @include overline_heading();
            margin: 0;
            text-align: left;
            color: rgba(5, 5, 82, 0.8);
          }
        }
        &_card {
          padding: 8px 12px;
          font-family: $font_inter;
          &_title {
            font-size: 13px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: rgba(5, 5, 82, 0.8);
            margin-top: 0px;
            transition: 0.1s ease;
            &:hover {
              cursor: pointer;
            }
          }
          &_subtitle {
            font-size: 10px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.8px;
            text-align: left;
            color: rgba(5, 5, 82, 0.4);
          }
          &_speechtext {
            &_div {
              border-radius: 6px;
              border: solid 0.5px rgba(5, 5, 82, 0.1);
            }
            &_row {
              display: flex;
              border-bottom: 1px solid rgba(5, 5, 82, 0.1);
              &:last-child {
                border-bottom: none;
              }
              &:nth-child(2n) {
                background-color: rgba(5, 5, 82, 0.02);
              }
            }
            &_small_col {
              flex: 2;
              padding: 12px;
              p {
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: left;
                color: rgba(5, 5, 82, 0.6);
                margin: 0;
              }
            }
            &_large_col {
              flex: 5;
              padding: 12px;
              p {
                font-size: 12px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                text-align: left;
                color: rgba(5, 5, 82, 0.8);
                margin: 0;
              }
            }
          }
          &_result {
            &_row {
              margin-top: 16px;
              border-radius: 6px;
              border: solid 0.5px rgba(5, 5, 82, 0.1);
              display: flex;
            }
            &_large {
              &_col {
                flex: 5;
                padding: 12px;
                p {
                  font-size: 12px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: normal;
                  letter-spacing: normal;
                  text-align: left;
                  color: rgba(5, 5, 82, 0.6);
                  margin: 0;
                }
              }
            }
            &_small {
              &_col {
                flex: 3;
                padding: 12px;
                p {
                  font-size: 12px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: normal;
                  letter-spacing: normal;
                  text-align: left;
                  color: rgba(5, 5, 82, 0.6);
                  margin: 0;
                }
              }
            }
          }
          &_images {
            &_row {
              margin-top: 16px;
              display: flex;
            }
            &_div {
              width: 140px;
              height: 220px;
            }
            &_col {
              flex: 1;
              margin-right: 8px;
              &:last-child {
                margin-right: 0 !important;
              }
              img {
                width: 100%;
                height: 100%;
                border-radius: 6px;
                object-fit: cover;
                font-size: 10px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.8px;
                text-align: left;
                color: rgba(5, 5, 82, 0.4);
              }
              p {
                font-size: 10px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.8px;
                text-align: left;
                color: rgba(5, 5, 82, 0.4);
              }
            }
          }
          &_image {
            width: 100%;
            height: 100%;
            border-radius: 6px;
            object-fit: contain;
            font-size: 10px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.8px;
            text-align: left;
            color: rgba(5, 5, 82, 0.4);
          }
          &_checks {
            margin-top: 16px;
            border-radius: 6px;
            width: 80%;
            border: solid 0.5px rgba(5, 5, 82, 0.1);
            background-color: rgba(5, 5, 82, 0.02);
            &_row {
              display: flex;
              border-bottom: 1px solid rgba(5, 5, 82, 0.1);
              &:last-child {
                border-bottom: none;
              }
              &:nth-child(2n + 1) {
                background-color: white;
              }
            }
            &_col {
              flex: 1;
              padding: 12px;
              p {
                margin: 0;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: left;
                color: rgba(5, 5, 82, 0.6);
              }
            }
          }
        }
      }
    }
    &_controls {
      font-family: $font_inter;
      width: 100%;
      padding: 16px;
      box-shadow: 0 0 15px 0 rgba(5, 5, 82, 0.05);
      border: solid 1px rgba(5, 5, 82, 0.1);
      border-radius: 3px;
      margin-top: 24px;
      &_slider_div {
        display: flex;
      }
      &_div {
        display: flex;
        align-items: center;
        margin: auto;
        &_info {
          flex: 2;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: rgba(5, 5, 82, 0.8);
        }
        &_parent {
          flex: 3;
          display: flex;
          padding: 0 2px;
          border-left: 1px solid rgba(5, 5, 82, 0.2);
          border-right: 1px solid rgba(5, 5, 82, 0.2);
        }
        &_icon {
          flex: 1;
          transition: 0.1s ease;
          height: 32px;
          width: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          &:hover {
            cursor: pointer;
            border-radius: 6px;
            background-color: rgba(5, 5, 82, 0.1);
          }
          &_playback {
            flex: 2;
            display: flex;
            align-items: center;
            justify-content: center;
            &_speed {
              width: 36px;
              display: flex;
              align-items: center;
              justify-content: center;
              height: 24px;
              border-radius: 3px;
              border: solid 1px rgba(5, 5, 82, 0.1);
              font-size: 12px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              text-align: left;
              color: rgba(5, 5, 82, 0.8);
              &:hover {
                cursor: pointer;
                background-color: rgba(5, 5, 82, 0.1);
              }
            }
          }
        }
      }
    }
  }
}

#multi {
  &_table {
    &_card {
      &__container {
        font-family: $font_inter;
        background-color: $border_grey;
        border-radius: 6px;
        border: solid 0.5px rgba(5, 5, 82, 0.1);
        color: $hyperblue;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-top: 0;
        margin-bottom: 16px;
        &__section {
          padding: 0 16px 16px 0px;
        }
        &__tab {
          padding: 16px;
          text-wrap: wrap;
        }
      }
    }
    &_panel {
      &__container {
        padding: 16px;
      }
      &__table {
        &__container {
          table {
            border-collapse: collapse;
            width: 100%;
            overflow: auto;
            border: 1px solid rgba(5, 5, 82, 0.1);
            border-radius: 6px;
            margin: 8px 0;
          }
          td {
            padding: 12px;
            font-size: 12px;
            color: $details_table_cell_color;
            min-width: 100px;
            p {
              margin: 0;
            }
          }
          td.heading {
            width: 30%;
          }
          td.heading p {
            font-weight: 600;
          }
          tr:nth-child(even) {
            background-color: $aml_table_background_colour !important;
          }
          tr {
            border-bottom: 1px solid rgba(5, 5, 82, 0.1);
          }
        }
      }
    }
  }
}

.failed_match p {
  color: #dd4a46 !important;
}

.apply-missing-effect {
  box-shadow: 0 0 15px 0 rgba(5, 5, 82, 0.1);
  cursor: not-allowed;
}

.missing-card-container {
  width: 100%;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease;
  overflow: hidden;
  padding: 32px 4px 32px 4px;
  gap: 40px;
  border-radius: 8px 0px 0px 0px;
  border: 1px 0px 0px 0px;
  border: 1px solid rgba(5, 5, 82, 0.05);
  background: rgba(5, 5, 82, 0.02);
  margin-bottom: 15px;
}

.top-content {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > p {
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.02em;
    text-align: center;
    color: rgba(5, 5, 82, 0.8);
  }

  > span {
    font-family: Inter;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: rgba(5, 5, 82, 0.6);
  }
}

.bottom-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > span {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    color: rgba(5, 5, 82, 0.4);
  }
}

#record__body__all_modules_button {
  display: flex;
  height: 40px;
  width: 141px;
  padding: 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid rgba(5, 5, 82, 0.1);
  background: #fff;
  color: rgba(5, 5, 82, 0.8);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;

  &:hover {
    cursor: pointer !important;
    border-radius: 6px;
    border: 1px solid rgba(5, 5, 82, 0.1);
    opacity: 0.8;
    background: rgba(5, 5, 82, 0.05);
  }
}

#overview__details__card__shadow {
  box-shadow: 0px 0px 10px 0px rgba(5, 5, 82, 0.05);
}
