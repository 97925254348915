@import "../../../App.scss";

$common_popup_box_shadow_border_color: rgba(5, 5, 82, 0.1);

#custom_popup {
  &_container {
    position: relative;
    &.assignee_popup_container {
      width: 95%;
    }
  }
  &_trigger {
  }
  position: absolute;
  z-index: 100;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 5px 15px 0 $common_popup_box_shadow_border_color;
  border: solid 0.5px $common_popup_box_shadow_border_color;

  &.bottom {
    bottom: -8px;
    transform: translate(0%, 100%);
    left: 0px;
  }
  &.bottom-right {
    bottom: -18px;
    transform: translate(0%, 100%);
    left: 0;
  }
  &.bottom-left {
    bottom: -8px;
    transform: translate(0%, 100%);
    right: 0px;
  }
  &.left {
    top: 0px;
    left: -8px;
    transform: translate(-100%, 0%);
  }

  &.top {
    top: -8px;
    transform: translate(0%, -100%);
    left: 0px;
  }
  &.top-right {
    top: -15px;
    transform: translate(0%, -100%);
    right: -9px;
  }
  &.centered-below {
    top: 100%; 
    left: 10%;
    transform: translate(-50%, 8px);
    z-index: 999;
  }
}

.assign_applications_button{
  width: 140px;
height: 32px;
padding: 4px 4px 4px 4px;
border-radius: 3px 3px 3px 3px;
display: flex;
justify-content: center;
align-items: center;
background: rgba(85, 78, 241, 1);


&:hover{
  cursor: pointer;
  background: rgba(255, 255, 255, 0.2);
  ;
}
}

.change_assignee_button{
  background-color: transparent;
  font-size: 14px;
  margin-left: 5px;
  cursor: pointer;
  color: rgba(85, 78, 241, 1);
}

.large-container {
  width: 499px;
  min-height: auto;
  max-height: 400px;
  padding-bottom: 20px;
 }

 .small-container {
  min-width: 336px;
  min-height: auto;
  max-height: 400px;
padding: 4px 0px 0px 0px;
gap: 4px;
border-radius: 8px 0px 0px 0px;
border: 1px 0px 0px 0px;
 }

 #selectAssignee_filtercard_search {
  display: flex;
  flex-direction: row;
  padding: 10px;
  border-radius: 5px;
  font-size: 13px;
  border: none !important;
  &.focus {
    box-shadow: none !important;
  }
  > img {
    cursor: pointer;
    pointer-events: none;
  }
  > input {
    width:100%;
    margin-left: 7px;
    border: none;
    outline-width: 0;
    &.close {
      width: 0;
      margin: 0;
      padding: 0;
    }
    &::placeholder {
      color: #05055266;
      font-family: Inter;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }
    &:focus {
      box-shadow: none !important;
    }
    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #05055266;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #05055266;
    }
  }
}

.divider-line-searchBar {
  display: block;
  width: 100%;
  height: 1px;
  background-color: rgba(5, 5, 82, 0.05);
}