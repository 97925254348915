@import "../../App.scss";

$custom_table_text_color: #050552; 
$custom_table_cell_subtext_text_color: rgba(5, 5, 82, 0.5);

button::-moz-focus-inner {
   padding: 0 !important;
   border: 0 none !important;
}

$usage_select_menu_item_text_color: rgba(5, 5, 82, 0.8);
$usage_body_header_text_color: rgba(5, 5, 82, 0.5);
$usage_header_text_color: #050552cc;

@mixin usage_button ()
{
    @include audit_button(); 
    margin-right: 16px;
    padding: 7px 13px; 
    > img {
        margin-right: 5px;
    }
}
@mixin usage_button_div ()
{
    > .usage_button {
        @include usage_button(); 
    }
}

.usage_select_button {
    @include usage_button();
    margin-right: 0px;
}

.usage_select_menu {
    max-height: 18em;
    > div {
        width: 100%;
        font-size: $subheading_font_size;
        font-weight: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: left;
        color: $usage_select_menu_item_text_color;
    }
}

.usage_table_cell {
    color: $custom_table_text_color;
    font-size: 14px;
    > .sub_text {
        margin-left: 2px;
        color: $custom_table_cell_subtext_text_color;
    }
}
#usage {
    &__container {
        height: 100%;
        padding: 32px;
        background-color: white;
    }
    &__header {
        display: flex;
        align-items: center;
        gap: 4px;
        > h1 {
            color: $usage_header_text_color;
            margin: 0px;
            display: inline;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 32px;
        }
        &_filters {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 16px 0px;
            margin-bottom: 20px;
            @include usage_button_div();
            align-items: center;
        }
    }
    &__summary {
        >h3 {
            color: rgba(5, 5, 82, 0.80);
            font-size: 20px;
            font-weight: 600;
            line-height: 28px;
            margin-top: 32px;
            margin-bottom: 12px;
        }
    }
    &__bill {
        display: flex;
        flex-direction: column;
        gap: 17px;
        margin-top: 20px;
        position: relative;

        #billing_summary_panel {
            width: 33%;
        }
    }
    &__body {
        margin-top: 7px;
        &_header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
            > p {
                font-size: 12px;
                font-weight: normal;
                color: $usage_body_header_text_color;
                margin: 0px;
            }
            @include usage_button_div();
        }
        &_table_container {
            margin-top: 20px;
            margin-bottom: 40px;
        }
    }
}

.platform-usage-container{
    margin-top: 20px;
    width: 70%;
}

.platform-usage-subtext {
    font-size: 13px;
    color: rgba(5, 5, 82, 0.6)
}

@media screen and (max-width: 900px) {
    .platform-usage-container {
        width: 100%;
    }    
}

.usage_filters {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

#bill_breakup_container {
    top: 70%;
    left: 34%;
    position: absolute;
    transform: none;
}