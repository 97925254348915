@import "../../../App.scss";

#credential {
  &__heading {
    @extend .font-design-heading;
    font-size: 24px;
    margin: 0px;

    &__creds {
      margin: 0px 1em;
    }
  }

  &__heading_container {
    display: flex;
    flex-direction: row;
    margin-bottom: 32px;
  }
}

#credential__table {
  &__container {
    overflow-x: scroll;
  } 
  tbody > tr:not(:hover) {
    background-color: transparent;
  }
}

#app_id_cell {
  &__text {
    display: inline-flex;
    padding: 0;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px; 
    > span{
      width: 50px;
    }
  }
  &__copy {
    margin-left: 8px;
    padding: 5px 12px;
    border-radius: 20px;
    background-color: rgba(85, 78, 241, 0.05);
    color: #554ef1;
    cursor: pointer;
    &.disabled {
      opacity: 0;
    }
  }
}

#app_key_cell {
  &__container {
    display: inline-flex;
    padding: 0;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  &__text {
    display: flex;
    align-items: center;
    font-size: 14px;
    width: 180px;
  }
  &__show_test {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__hide_text {
    display: flex;
    justify-content: center;
    align-items: center;
    > span {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 8px;
      > span {
        display: inline-block;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: #7a7171;
        margin-right: 4px;
        &:last-child {
          margin-right: 0px;
        }
      }
    } 
  }
  &__view {
    margin-left: 8px; 
    padding: 5px 12px;
    border-radius: 20px;
    background-color: rgba(85, 78, 241, 0.05);
    color: #554ef1;
    cursor: pointer;
    &.disabled {
      opacity: 0;
    }
  }
  &__copy {
    margin-left: 8px; 
    padding: 5px 12px;
    border-radius: 20px;
    background-color: rgba(85, 78, 241, 0.05);
    color: #554ef1;
    cursor: pointer;  
    &.disabled {
      opacity: 0;
    }
  }
}
