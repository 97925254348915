.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
  }
  
  .modal-content {
    background-color: #fff;
    // padding: 20px;
    border-radius: 8px;
    width: 453px;
    border-radius: 8px;
    box-shadow: 0px 0px 15px 0px rgba(5, 5, 82, 0.10);
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    z-index: 20;
    color: rgba(5, 5, 82, 0.8);

  }
  
  .modal-header {
    display: flex;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
    color: #050552CC;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    border-radius: 8px 8px 0px 0px;
    border-bottom: 1px solid rgba(5, 5, 82, 0.10);
    background: #FFF;
  
    h3 {
      margin: 0;
    }
  
    .close-button {
      font-weight: 200;
      width: 20px;
      height: 20px;
      background: none;
      border: none;
      font-size:25px;
      cursor: pointer;
    }
  }
  
  .modal-body {
  
    .application-count {
      .application-item {
        display: flex;
        justify-content: space-between;
        padding: 4px 0;
        padding-left: 10px;
        padding-right: 10px;
        color: rgba(5, 5, 82, 0.80);
        font-family: Inter;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
  
        &.collapsible {
          cursor: pointer;
          border-radius: 5px;
          padding-right: 10px;
  
          &:hover {
            background-color: #f0f0f5;
          }
        }
      }

      .application-item-heading {
        padding-left: 21px;
        padding-right: 12px;
      }
  
      .collapse-content {
        padding-left: 10px;
        color: #6c757d;
        font-size: 11.5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
  
        .application-entry {
          display: flex;
          justify-content: space-between;
          width: 100%;
          padding: 5px 0;
          padding-left: 11px;
          padding-right: 15px;
          border-radius: 3px;
          color: rgba(5, 5, 82, 0.80);
          font-family: Inter;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;

          &:hover {
            border-radius: 4px;
            background: rgba(5, 5, 82, 0.03);
          }
  
          span {
            &:first-child {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
  
  .modal-footer {
    display: flex;
    justify-content: flex-end;
    height: 56px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 12px;
    gap: 8px;
    border-top: 1px solid rgba(5, 5, 82, 0.10);
    background: #FFF;

    >button {
      font-weight: 500 !important;
    }
  
    .cancel-button,
    .confirm-button {
      padding: 7px 15px;
      border-radius: 5px;
      border: none;
      cursor: pointer;
    }
  
    .cancel-button {
      border: 1px solid #05055233;

      &:hover {
        background-color: #f0f0f0;
      }
    }
  
    .confirm-button {
      padding: 8px, 16px, 8px, 16px;
      background: rgba(85, 78, 241, 1);
      color: white;

      &:hover {
        background-color: #914fe7;
      }
    }
  }

.count_text {
    color: rgba(5, 5, 82, 0.8);
    font-size: 13px !important;
    font-weight: 600;
}

.arrow {
    transition: transform 0.3s ease-in-out;
    margin-right: 7px;
    z-index: 30;
    color: rgba(85, 78, 241, 1);
    font-size: 13px;
    font-weight: 200;
}

.open {
    transform: rotate(90deg);
}

.modal_body_info_text{
  // width: 521px;
  color: rgba(5, 5, 82, 0.80);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  padding-left: 21px;
  padding-right: 12px;
  padding-top: 0;
  margin-top: 0;
  margin-bottom: 12px;
  // white-space: wrap;
  // word-break: break-word;
}

.border-bottom{
  border-block-end: 1px solid #eaeaea;
  font-weight: 500 !important;
  line-height: 20px !important;
  font-size: 13px !important;
  margin-bottom: 10px;
}

.applications_modal_body {
  max-height: 264px;
  overflow-y: scroll;
  padding-left: 16px;
  padding-top: 12px;
  padding-bottom: 20px;
  padding-right: 16px;
}
  
.total-text {
  font-weight: 500 !important;
  line-height: 20px !important;
  font-size: 13px !important;
}

.modal-body-record {
padding: 12px 16px 20px 16px;
gap: 16px;
font-family: Inter;
font-size: 13px;
font-weight: 400;
line-height: 18px;
text-align: left;
text-decoration-skip-ink: none;

}
.model-content-reviewButton {
  padding: 12px 16px 20px 16px;
gap: 16px;
font-family: Inter;
font-size: 13px;
font-weight: 400;
line-height: 18px;
text-align: left;
text-decoration-skip-ink: none;
}
