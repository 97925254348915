.pricing-info-loader {
    position: absolute;
    transform: translate(-50%, -60%);
    top: 50%;
    left: 60%;
}

#client_pricing_info {
    &__header {
        &__container {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;

            h2 {
                font-size: 24px;
                font-weight: 500;
                letter-spacing: 0.24px;
                color: #050552;
                margin: 0px;
            }
        }
    }

    &__container {
        padding: 32px 32px;
        height: 100%;
    }

    &__body_container {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    &__module_header_display {
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: center;
    }

    &__display {
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: center;
        margin-top: 15px;
    }

    &__font {
        font-size: 20px;
        font-weight: 500;
    }

    &__sub_heading {
        margin: 10px 0;
    }
}