@import "../../../../../App.scss";

$generic_common_card_tab_color: rgba(5, 5, 82, 0.6);
$generic_common_card_tab_active_color: #554ef1;
$generic_common_card_table_border: 0.5px solid rgba(5, 5, 82, 0.1);
$generic_common_card_table_cell_color: rgba(5, 5, 82, 0.8);

@mixin common_status_styles {
  font-size: 12px;
  font-weight: bold;
  margin: 2px;
  padding: 0.5em 1.75em;
  border-radius: 20px;
}

@mixin generic_common_card_container {
  &__container {
    background-color: white;
    border-radius: 6px;
    border: solid 0.5px rgba(5, 5, 82, 0.1);
    color: $hyperblue;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

@mixin generic_common_card_header {
  &__header {
    &__container {
      margin: 0px;
      padding: 16px !important;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }

    &__title {
      margin-left: 12px;
      @include heading_inside_modal_bold();
      color: #050552;
    }

    &__image {
      width: 35px;
      height: 35px;
      background-color: #f6f7ff;
      border-radius: 3px;
      background-color: rgba(85, 78, 241, 0.05);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      >svg {
        color: rgba(5, 5, 82, 0.8);
        height: 16px;
        width: 16px;
      }
    }
  }
}

@mixin generic_common_card_body() {
  &__body {
    &_tabs {
      min-height: unset;
      border-bottom: 0.5px solid #ebeef1;
      margin: 0px 16px;
      padding: 0px !important;

      button {
        @include secondary_text();
        color: $generic_common_card_tab_color !important;
        margin-right: 30px !important;
        text-transform: none !important;
        padding: 0px;
        min-height: unset;
        padding-bottom: 9px;
      }

      button.tabActive {
        color: $generic_common_card_tab_active_color !important;
        font-weight: 600 !important;
      }

      span.MuiTabs-indicator {
        background-color: $generic_common_card_tab_active_color !important;
        height: 1px !important;
      }
    }
  }
}

@mixin generic_common_card_body_tab_header {
  &__header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 32px;
    flex-wrap: wrap;
    gap: 24px 24px;

    &__status {
      &__approved {
        @include common_status_styles();
        background-color: #e3fcef;
        color: #006644 !important;
      }

      &__declined {
        @include common_status_styles();
        background-color: #ffebe6;
        color: #bf2600 !important;
      }

      &__review {
        @include common_status_styles();
        background-color: #ffe19b59;
        color: #9a4c24 !important;
      }

      &__dropped {
        @include common_status_styles();
        background-color: #f4f5f7;
        color: rgba(5, 5, 82, 0.6) !important;
      }

      &__applying {
        @include common_status_styles();
        background-color: #deebff;
        color: #0747a6 !important;
      }

      &__reported {
        @include common_status_styles();
        background-color: #deebff;
        color: #0747a6 !important;
      }
    }

    p {
      @include base_font_and_inputs();
      color: black;
      margin: 0px;
      color: rgba(5, 5, 82, 0.6);
      white-space: nowrap;

      span {
        color: rgba(5, 5, 82, 0.6);
        margin-left: 7px;
        font-weight: 500;
      }

      button:focus {
        color: #554ef1 !important;
      }
    }
  }
}

@mixin generic_common_card_body_tab_body {
  &__body {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;

    &__column {
      flex-direction: column;
    }

    h1 {
      @include overline_heading();
      color: rgba(5, 5, 82, 0.4);
      margin: 0px;
      margin-bottom: 16px;
      white-space: nowrap;
    }

    h2 {
      @include secondary_text();
      text-align: left;
      color: rgba(5, 5, 82, 0.8);
      margin: 0px;
      margin-bottom: 15px;
      white-space: nowrap;
    }

    &__image {
      &__container {
        flex: 0 1;
        margin-right: 48px;

        p {
          @include base_font_and_inputs();
          color: black;
          margin: 0px 35px 0px 0px;
          color: rgba(5, 5, 82, 0.6);
          margin-bottom: 24px;

          span {
            font-weight: 500;
            margin-left: 7px;
            color: rgba(5, 5, 82, 0.6);
          }
        }
      }

      margin: 0px;
      margin-bottom: 24px;
    }

    &__table {
      &__container {
        width: 100%;
        overflow-x: scroll;
        scrollbar-width: none;
      }

      scrollbar-width: thin;
      scrollbar-color: rgba(4, 4, 73, 0.409) rgba(4, 4, 73, 0);

      border: $generic_common_card_table_border;
      border-radius: 6px;
      overflow: auto;

      table {
        border-collapse: collapse;
        width: 100%;
        overflow: auto;
      }

      td {
        @include secondary_text();
        padding: 12px;
        color: $generic_common_card_table_cell_color;
        min-width: 100px;

        >span.conf {
          display: none;
        }
      }

      td.right {
        @include secondary_text();
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
      }

      td.left {
        @include secondary_text();
        font-weight: 400;
        width: 120px;
        >div {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: inherit;
        }
      }

      tr:nth-child(even) {
        background-color: rgba(5, 5, 82, 0.02) !important;
      }
    }
  }
}

@mixin generic_common_card_body_tab {
  &__tab {
    &__container {
      padding: 16px !important;
      display: flex;
      flex-direction: column;
    }

    @include generic_common_card_body_tab_header();
    @include generic_common_card_body_tab_body();
  }
}

@mixin generic_common_card_section {
  &__section {
    margin-bottom: 24px;
    overflow-x: hidden;

    &:last-of-type {
      margin-bottom: 0;
    }

    &__header {
      h2 {
        font-size: 10px;
        font-weight: bold;
        letter-spacing: 0.8px;
        color: rgba(5, 5, 82, 0.4);
        text-transform: uppercase;
      }
    }

    &__body {
      display: flex
    }
  }
}

.generic_common_card {
  @include generic_common_card_container();
  @include generic_common_card_header();
  @include generic_common_card_body();
  @include generic_common_card_body_tab();
  @include generic_common_card_section();
}