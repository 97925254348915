.BU-selection-toggle-dropdown {
  &__header {
    border-radius: 8px;
    margin-bottom: 8px;
    box-sizing: border-box;
    height: 48px;
    padding: 8px 16px 8px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-arrow {
      height: 24px;
      width: 24px;
    }
    
    &:hover {
      background: #05055208;
      cursor: pointer;
    }

    &_expanded {
      background: #05055208;
      border: 1px solid #0505521A;
    }

    &-content {
      display: flex;
      align-items: center;
    }

    &-title {
      font-family: Inter;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 12px;
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      color: #05055299;
      margin-left: 10px;
    }

    &-subtitle {
      &_dot-icon {
        margin: 0 8px;
        width: 3px;
        height: 3px;
      }

      display: flex;
      align-items: center;
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      color: #050552CC;
    }
  }

  &__options-list {
    &-item {
      padding: 8px 20px 8px 20px;
      display: flex;
      justify-content: space-between;
      border-radius: 8px;
      color: #050552CC;
      font-size: 13px;
      margin-bottom: 4px;

      &:hover &__arrow-right-icon-container {
        display: flex;
        align-items: center;
      }

      &__arrow-right-icon {
        &-container {
          display: none;
        }

        & > path {
          fill: #050552;
        }

        width: 12px;
      }

      &:hover {
        cursor: pointer;
        background: #05055208;
      }
    }
  }
}

.avatar {
  height: 32px;
  width: 32px;
  border-radius: 6px;
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 13px;
}