@import "../../../../../App.scss";

.card-text {
    margin: 0px;
    margin-bottom: 16px;

    &.h1 {
        @include heading_inside_modal_semi_bold();
        color: rgba(5, 5, 82, 0.8);
    }

    &.h2 {
        @include secondary_text();
        font-weight: 600;
        color: rgba(5, 5, 82, 0.6);
    }

    &.h3 {
        @include overline_heading();
        color: rgba(5, 5, 82, 0.4);
        margin: 0px;
        margin-bottom: 16px;
        white-space: nowrap;

    }

    &.uppercase {
        text-transform: uppercase;
    }

    &.lowercase {
        text-transform: lowercase;
    }

    &.capitalize {
        text-transform: capitalize;
    }

    &.center {
        text-align: center;
    }

    &.error {
        color: #dd4a46;
    }
}

.card_section {
    margin-bottom: 16px;

    &__body {}
}

.card_table {

    border-radius: 6px;
    border: solid 0.5px rgba(5, 5, 82, 0.1);
    overflow: auto;

    &__container {
        margin-bottom: 16px;
    }

    &__row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        gap: 16px;
        padding: 10px;

        &.title_row,
        &.header_row {
            font-size: 12px;
            font-weight: 600;
            color: rgba(5, 5, 82, 0.8);
            background-color: rgba(5, 5, 82, 0.02);
        }

        &.odd {
            &:nth-child(odd) {
                background-color: rgba(5, 5, 82, 0.02);
            }
        }

        &.even {
            &:nth-child(even) {
                background-color: rgba(5, 5, 82, 0.02);
            }
        }

        &__key {
            @include secondary_text();
            color: rgba(5, 5, 82, 0.8);
            max-width: 200px;
        }

        &__value {
            @include secondary_text();
            color: rgba(5, 5, 82, 0.8);
            max-width: 200px;
        }

    }
}

.card_image {
    align-self: center;
    overflow: hidden;
    border-radius: 6px;

    &.start {
        align-self: flex-start;
    }

    &.center {
        align-self: center;
    }

    &.end {
        align-self: flex-end;
    }

    >img {
        height: 100%;
        width: auto;
    }

    &__container {
        margin-bottom: 16px;
        display: flex;
        flex-direction: column;
    }

}

.card_table_image {
    &__container {
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;
    }

    &__body {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        gap: 16px;

        &.reverse {
            flex-direction: row-reverse;
        }

        >.card_table__container {
            flex: 1;
        }

        >div {
            margin: 0px !important;
        }
    }
}


.card_collapse {

    &__container {
        margin-bottom: 16px;
    }

    &__button {
        display: flex;
        align-items: center;
        font-size: 10px;
        font-weight: bold;
        letter-spacing: 0.8px;
        color: rgba(5, 5, 82, 0.4);
        text-transform: uppercase;
        cursor: pointer;

        >svg {
            margin-left: 4px;

            >path,
            g {
                fill: rgba(5, 5, 82, 0.4);
            }
        }

        &.active {
            >svg {
                transform: rotate(180deg);
            }
        }
    }

    &__children {
        margin-top: 16px;
        overflow: auto;
    }
}

.column_layout {
    &__container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;

        >div {
            flex: 1 1 auto;
        }
    }

}

.column {
    &__container {
        display: flex;
        flex-direction: column;
        overflow: auto;
        margin-bottom: 16px;

        &.wrap {
            flex-wrap: wrap;
        }

        &.v-center {
            justify-content: center;
        }

        &.h-center {
            align-items: center;
        }
    }
}


.card_pdf {
    align-self: center;
    overflow: hidden;
    border-radius: 6px;

    &.start {
        align-self: flex-start;
    }

    &.center {
        align-self: center;
    }

    &.end {
        align-self: flex-end;
    }

    >img {
        height: 100%;
        width: auto;
    }

    &__container {
        margin-bottom: 16px;
        display: flex;
        flex-direction: column;
    }
}