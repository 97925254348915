@import "../../../App.scss";

#new_application_modal {
  &__container {
    position: relative;
    top:-35px;
    background-color: $border_grey;
    border-radius: 6px;
    width: 35em;
    height: 35em;
    color: $hyperblue;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 10px;
  }
  &__header_container {
    padding: 20px;
    border-bottom: 1px solid rgb(238, 238, 238);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    &__heading {
      font-size: 20px;
      font-weight: 600;
      margin: 0px 0px;
      color: #050552;
    }
    &__button {
      &:hover, &:focus, &:active {
        background-color: rgb(230, 230, 230) !important;
        outline: none;
        color: gray;
        box-shadow: none;
    }
      font-size: 12px !important;
      background-color: rgb(242, 242, 242) !important;
      color: rgb(151, 148, 148);
      padding:0px !important;
      margin: 0px !important;
      border-radius: 50%;
      min-width: 0px !important;
    }
  }
  &__body_container {
    display: flex;
    flex-direction: column;
    justify-self: inherit;
    padding: 20px;
    height: 100%;
    overflow-y: scroll;
    * {
      text-transform: none;
    }
    &__subheading {
      font-size: $subheading_font_size;
      font-weight: 300;
      margin: 0px 0px 10px 0px;
      color: rgba(5, 5, 82, 0.6);
      &_error {
        margin-top: 5px;
        color: red;
        font-size: 13px;
      }
    }
    &__input_label {
      font-size: $subheading_font_size;
      color: rgba(5, 5, 82, 0.8);
    }
    &__input {
      font-size: 13px;
    }
    &__input:focus + fieldset {
      border: 1px solid #8185f1;
    }
    &__input + fieldset {
      border: 1px solid lightgrey;
    }
    &__workflow_select {
      &_container{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0px 0px 10px 0px;
        > p {
          margin: 0;
          font-size: 12px;
          font-weight: 300;
          font-stretch: normal;
          white-space: nowrap;
        }
      }
    }

    &__link_container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 20px;
    }

    &__link {
      padding: 20px 0px;
      text-align: center;
      text-overflow: clip;
      display: inline-block;
      color: #554ef1;
    }
    &__link_copy {
      background-color: #f6f7ff !important;
      color: #496cff !important;
      margin: 0px 20px;
      font-size: 12px;
    }
    &__link_copy:hover {
      background-color: #e6e7ee !important;
    } 
  }
  &__footer_container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    border-top: 1px solid rgb(238, 238, 238);
    padding: 20px;
    * {
      text-transform: none;
    }
    &__generate_button_wrapper {
      display: flex;
      flex-direction: column;
    }
    &__generate_button_wrapper.disabled {
      cursor: not-allowed;
    }
    &__generate_button {
      background-color: #554ef1;
      color: white;
      padding: 11px 22px;
      font-weight: 300;
      font-size: 15px;
      border-radius: 7px;
    }
    &__generate_button.disabled {
      pointer-events: none;
      opacity: 0.65;
    }
    &__generate_new_button {
      background-color: #554ef1;
      color: white;
      padding: 11px 22px;
      font-weight: 300;
      font-size: 15px;
      border-radius: 7px;
    }
    &__generate_new_button:hover {
      background-color: #554ef1;
      color: white;
    }
  }
}
