$slideshow_background_color: rgba(1, 1, 28, 0.9);
$slidshow_image_header_color:rgba(5, 5, 82, 0.8);
$slideshow_control_color: $slidshow_image_header_color;
$slideshow_control_disabled_color: rgba(5, 5, 82, 0.4);
$slideshow_control_disabled_image_opacity: 0.4;
$slideshow_control_line_color: rgba(5, 5, 82, 0.2);
$slideshow_control_active_bg_color: rgba(5, 5, 82, 0.1);
$common_image_box_shadow: 0 0 4px 0 rgba(0, 0, 0, 0.08);

.common_pdf_renderer {
    &__container {
        height: 100%;
    }

    &__document {
        width: auto;
        height: 100%;
        position: relative;
        // box-shadow: rgba(16, 36, 94, 0.2) 0px 30px 40px 0px;
        border-radius: 10px;
        cursor: pointer;
        display: flex;

        &:hover {
            .common_pdf_renderer__controls {
                opacity: 1;
            }
        }

        .react-pdf__message--error {
            margin: auto;
            font-size: 14px;
            color: $slidshow_image_header_color;
        }
    }

    &__page {
        width: auto;
        height: 100%; 
        overflow: hidden;
        border-radius: 10px;
        min-width: unset !important;
        min-height: unset !important;

        canvas {
            width: auto !important;
            height: 100% !important;
        }

        // TODO: Check if this is required
        .react-pdf__Page__annotations {
            display: none;
        }
        
        .react-pdf__Page__textContent {
            display: none;
        }
    }

    &__controls {
        position: absolute;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 10px;
        bottom: 5%;
        left: 50%;
        background: white;
        opacity: 0;
        transform: translate(-50%);
        transition: opacity ease-in-out .2s;
        box-shadow: rgba(16, 36, 94, 0.2) 0px 30px 40px 0px;
        border-radius: 5px;;
        z-index: 2;

        >button {
            background: white;
            border: 0;
            border-radius: 5px;
            padding: 10px 20px;
            &:hover {
                cursor: pointer;
                background-color: $slideshow_control_active_bg_color;
            }
        }
    }
}


.common_pdf_viewer {
    &__container {
        width: 100vw;
        height: 100vh;
        overflow: auto;
        padding: 40px !important;
        padding-bottom: 70px !important;
        position: fixed;
        top: 0;
        left: 0;
        background-color: $slideshow_background_color;
        z-index: 99999;
        display: flex;
        flex-direction: column;
        gap: 5%;
    }

    &__pdf {
        &__container {
            // height: 85%;
            height: 100%;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            overflow: auto;
        }

        &__header {
            z-index: 99999;
            top: 16px;
            left: 50%;
            transform: translate(-50%, 0%);
            position: absolute;
            border-radius: 3px;
            box-shadow: $common_image_box_shadow;
            background-color: #fff;
            padding: 3px 12px;
            font-size: 14px;
            color: $slidshow_image_header_color;
            font-weight: 400;
            pointer-events:none
        }

        height: 100%;
        width: auto;
        max-height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        overflow: auto;
    }

    &__control {
        z-index: 99999;
        
        &__container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        box-shadow: 0 0 15px 0 rgba(5, 5, 82, 0.15);
        border: solid 0.5px rgba(5, 5, 82, 0.1);
        background-color: #fff;
        border-radius: 6px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 4px;
        border-radius: 10px;

        >div {
            display: flex;
            padding: 12px;
            cursor: pointer;
            color: $slideshow_control_color;

            &.line {
                border-left: 0.5px solid $slideshow_control_line_color;
                height: 80%;
                padding: 0;
                margin: 0px 2px;
                // display: block;
                pointer-events: none;
            }
            &.disabled {
                color: $slideshow_control_disabled_color;
                cursor: not-allowed;
                > img {
                   opacity: $slideshow_control_disabled_image_opacity; 
                }
            }

            &.active,
            &:hover:not(.disabled) {
                border-radius: 6px;
                background-color: $slideshow_control_active_bg_color;
            }
        }
    }

    &__control_container {
        position: fixed;
        margin-top: 10px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}
