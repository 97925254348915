.MuiTimeline-positionRight {
    padding: 0 !important;
}

.MuiTimelineItem-positionRight::before {
    content: none !important;
    display: hidden;
}

.MuiTimelineConnector-root {
    width: 0px;
    border-left: 1px dashed rgba(5, 5, 82, 0.2);
    background-color: transparent;
}

.MuiTimelineDot-root {
    border-radius: 2px;
    background-color: rgba(5, 5, 82, 0.05);
    box-shadow: none;
    margin: 0;
    transition: 0.1s ease;
}

.MuiTimelineDot-root:hover {
    cursor: pointer;
}

.MuiTimelineDot-root p {
    font-size: 12px;
    margin: 0;
    color: rgba(5, 5, 82, 0.8);
}

.MuiTimelineContent-root {
    padding: 0;
}

.MuiSlider-root {
    padding: 4px 0;
    border-radius: 0;
}

.MuiTimelineItem-root {
    padding-top: 4px;
}