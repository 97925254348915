.MFA_common_card {
    &__container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 48px;
        padding-top: 24px;
        padding-bottom: 24px;
        background-color: white;
    }

    &__header {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 30px;

        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        >img {
            height: 100%;
            width: auto;
            max-height: 32px;
            margin-bottom: 10px;
        }

        >h1 {
            white-space: nowrap;
            font-size: 20px;
            font-weight: 500;
            color: #000000;
            margin: 0px;
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-bottom: 30px;

        >p {
            margin: 0;
            margin-bottom: 10px;
        }
    }

    &__footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;

        >button {
            width: 100%;
        }
    }
}

#MFA_options {
    &__container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__list {
        width: 100%;
    }
}

.MFA_resend {
    margin: 30px 0px 0px 0px !important;
    font-size: 13px;
    display: flex;
    align-items: center;
    gap: 5px;
}

.MFA_email_otp {
    width: 270px;
}