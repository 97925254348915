@import "../../App.scss";

.application {
  
  &__container {
    display: flex;
    flex-direction: column;
  }
  &__heading {
    @extend .font-design-heading;
    margin-bottom: 0;
  }

  &__subheading {
    @extend .font-design-heading;
  }

  &__creds {
    margin: 0px 1em; 
  }


  &__subheading_container {
    display: flex !important;
    align-items: flex-start !important;
    justify-content: space-between;
  }

  &__subheading_appId_container {
    display: flex !important;
    align-items: flex-start !important;
    justify-content: space-between;
  }

  &__subheading_button {
    @include base_font_and_inputs();
    background-color: #554ef1 !important;
    color: white !important;
    text-transform: none !important;
    border-radius: 5px !important;
    padding: 7px 15px !important;
  }

  &__subheading_button:hover {
    background-color: #3d33f0 !important;
  }
  &__subheading_button_end {
    margin: 0;
    padding: 1px 5px;
    font-size: 10px !important; 
    font-weight: 600 !important;
    background-color: #A19DF8;
    border-radius: 6px;
  }
  &__subheading_text {
    font-size: $subheading_font_size;
    font-weight: 300;
    color: rgba(5, 5, 82, 0.6);
  }
}

%menu {
  width: 2.91em;
  height: 1.5em;
  font-size: 12px;
  font-weight: 600;
  color: #f0f0f0;
}

.modal {
  &__Rectangle-51 {
      width: 95%;
      min-height: 13.2em;
      margin-top: 24px;
      padding: 2em 1em 2em 1.75em;
      border-radius: 6px;
      box-shadow: 0 0 15px 0 rgba(239, 239, 239, 0.25);
      border: solid 0.5px #dbdbdb;
      background-color: #ffffff;
      position: relative;
  }
  &__Rectangle-52 {
      width: 95%;
      min-height: 13.2em;
      margin-top: 24px;
      padding: 2em 1em 2em 1.75em;
      border-radius: 6px;
      box-shadow: 0 0 15px 0 rgba(239, 239, 239, 0.25);
      border: solid 0.5px #dbdbdb;
      background-color: #ffffff;
    }
  &__heading {
      height: 2.08em;
      margin: 0 26px 16px 0;
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: rgba(5, 5, 82, 0.8);
  }
  &__No_Code_container {
    height: 2.08em;
    display: inline;
    padding: 0.1em 1.25em 0.31em 1.25em;
    border-radius: 0.41em;
    background-color: #fff3c0;
    vertical-align: top;
  }
  &__No_Code_text {
      height: 1.25em;
      font-size: 0.6em;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.041em;
      text-align: left;
      color: #66632c;
  }

  &__sub_description_container {
    margin-top: 16px !important;
  }

  &__sub_description {
    font-size: $subheading_font_size;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: rgba(5, 5, 82, 0.8);
  }

  &__link_container {
    margin-top: 70px !important;
    color:#335aff !important;
    width : 95%;
  }

  &__link {
    float: right;
    text-decoration: none !important;
    font-weight: 600;
    display: flex;
    
  }

  &__link:hover {
    cursor: pointer;
  }

  &__link_arrow {
    margin-left: 4px;
  }

  &__hv_logo_horizontal-2 {
    width: 9em;
    height: 2em;
    margin: 0 0 2.5em;
    &__link {
      text-decoration: none !important;
    }
    &__logout {
      width: 3.75em  !important;
      height: 1.5em !important ;
      margin: 25em 5.33em 2.75em -1.5em !important ;
      font-size: 12px !important;
      font-weight: 600 !important;
      font-stretch: 'normal' !important;
      font-style: 'normal' !important;
      line-height: 'normal' !important;
      letter-spacing: 'normal' !important;
      text-align: 'left' !important ;
      color: #f0f0f0 !important ;
    }
  }
  &__Rectangle-1 {
    width: 18.66em;
    height: 66.66em;
    margin: 0 2.66em 8.83em 0;
    padding: 2.66em 2.66em 1.91em;
    background-color: #00034d;
  }
  &__Home {
    width: 2.91em;
    height: 1.5em;
    margin: 2.75em 5.33em 2.75em 1.16em;
    font-size: 1em;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #f0f0f0;
  }
}
