@import "../../../App.scss";

$filter_card_group_title_color: rgba(5, 5, 82, 0.4);
$filter_card_item_color: rgba(5, 5, 82, 0.8);
$filter_card_item_disabled_color: rgba(5, 5, 82, 0.4);
$filter_card_item_active_color: #554ef1;
$edit_filter_button_span_color: #554ef1;


#add_filter_button { 
    @include audit_button();
    > span {
        white-space: nowrap;
        margin-left: 5px;
        font-weight: 500;
    }
    &.active {
        > img { 
            transform: rotate(45deg)
        }
    }
}

#edit_filter_button { 
    @include audit_button();
    &_container {
        position: relative;
        > #edit_filter_close {
            opacity: 0;
            cursor: pointer;
            position: absolute;
            top:50%;
            transform: translate(-50%, -50%);
            right: 5px;
            background-color: white;
            display: flex;
            justify-content: center;
            transition: 0.25s;
        }
        &:hover {
            > #edit_filter_close {
                opacity: 1;
                position: absolute;
                > img {
                    filter: brightness(0) invert(33%) sepia(55%) saturate(7149%) hue-rotate(238deg) brightness(98%) contrast(93%) saturate(100%);
                    height: 100%;
                }
            }
        }
    }
    &_label {
        display: flex;
        gap: 10px 0px;
        flex-wrap: wrap;
        font-weight: 500;
        > span {
            color: $edit_filter_button_span_color;
            margin: 0px 3px;
            &::before {
                content: open-quote;
            }
            &::after {
                content: close-quote;
            }
        }
    }
    > span {
    }
    > img {
        display: none;
    }
    &:hover {
        > img {
            display: inline-flex;
        }
    }
}


#filter_card {
    &_container {
        padding: 24px;
        min-width: 400px;
    }
    &_container_selectAssignee {
        padding: 12px;
        overflow-y: auto;
        border-radius: 6px;
        padding: 4px;
        gap: 4px;
        border: 1px solid #0505521A;
        }
    &_search_container {
        > div {
            width: 100%;
        }
    }
    &_group_container {
        @include base_font_and_inputs();
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        &.empty {
            align-items: center;
            justify-content: center;
        }
        > h2 {
            @include overline_heading();
            letter-spacing: 0.5px;
            color: $filter_card_group_title_color;
            text-transform: uppercase;
        }
    }
    &_group_container_selectAssignee {
        @include base_font_and_inputs();
        display: flex;
        flex-direction: column;
        &.empty {
            font-size: 13px;
            align-items: center;
            justify-content: center;
        }
        > h2 {
            font-size: 10px;
            letter-spacing: 0.5px;
            color: $filter_card_group_title_color;
            text-transform: uppercase;
        }
    }
    &_items_container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        color: $filter_card_item_color;
        margin-top: 0px;
        min-width: 400px;
        > button {
            @include base_font_and_inputs();
            flex: 1 1 33%;
            max-width: 33%;
            margin: 10px 0px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-left: 0px;
            cursor: pointer;
            &:hover {
                color: $filter_card_item_active_color;
            }
            &.disabled {
                color: $filter_card_item_disabled_color;
                cursor: not-allowed;
            }
        }
    }
    &_items_container_nowrap {
        display: flex;
        flex-direction: column;
        color: $filter_card_item_color;
        margin-top: 0px;
        min-width: 200px;
        padding: 10px;
        > button {
            @include base_font_and_inputs();
            flex: 1 1 33%;
            max-width: 100%;
            padding: 7px;
            margin: 5px 0px;
            white-space: nowrap;
            text-overflow: ellipsis;
            border-radius: 5px;
            cursor: pointer;
            &:hover {
                background: rgba(85, 78, 241, 0.1);
            }
            &.disabled {
                color: $filter_card_item_disabled_color;
                cursor: not-allowed;
            }
        }
    }
    &_items_container_nowrap_selectAssignee {
        display: flex;
        flex-direction: column;
        color: $filter_card_item_color;
        margin-top: 4px;
        max-width: 500px;
        white-space: nowrap;
        text-overflow: ellipsis;
        > button {
            @include base_font_and_inputs();
            display: flex;
            max-width: 100%;
            margin: 2px 0px;
            border-radius: 6px;

            > span {
                @include base_font_and_inputs();
                width: 70%;
                flex: 1;
                margin-left: 12px;
                color: rgba(5, 5, 82, 1);
                overflow: hidden;
                padding: 8px 0px;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            &:hover {
                border-radius: 6px;
                background: rgba(5, 5, 82, 0.03);
                cursor: pointer;
            }
            &.disabled {
                color: $filter_card_item_disabled_color;
                cursor: not-allowed;
            }
        }
    }
}

.assign_applications_button{
    width: 140px;
  height: 32px;
  padding: 4px 4px 4px 4px;
  border-radius: 3px 3px 3px 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(85, 78, 241, 1);


  &:hover{
    cursor: pointer;
    background: rgba(255, 255, 255, 0.2);
    ;
  }
}

#selectAssignee_filtercard_search {
    width: 426px;
    height: 40px;
}

#filter_card_role_container {
    position: relative; 
    padding-left: 12px;
    color: rgba(5, 5, 82, 0.80);;
    font-family: Inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0.22px;
    text-transform: uppercase;
  }
  
  #filter_card_role_container::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 95%;
    height: 1px;
    background-color: #0505521A;
  }

.large-container {
 min-width: 468px;
 max-width: 468px;
 min-height: auto;
 max-height: 400px;
 padding-bottom: 20px;
}

.small-container {
    min-width: 420px;
    min-height: auto;
    max-height: 400px;
  padding: 4px 0px 0px 0px;
  gap: 4px;
  border-radius: 8px 0px 0px 0px;
  border: 1px 0px 0px 0px;

   }
