@import "../../../../../App.scss";
@import "../../../../Common/Inputs/Inputs.scss";

.card_type_five {
    &__container {
        background-color: white;
        border-radius: 6px;
        border: solid 0.5px rgba(5, 5, 82, 0.1);
        color: $hyperblue;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 16px;

        &__section {
            &:not(:first-of-type) {
                margin-top: 32px;
            }
        }
    }
    &__spinner_container {
        width: 100%;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__header {
        &__container {
            margin: 0px;
            margin-bottom: 16px;
            padding: 16px 16px 0px 16px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
        }

        &__title {
            @include heading_inside_modal_bold();
            margin-left: 12px;
            letter-spacing: 0.32px;
            color: #050552;
        }

        &__image {
            width: 35px;
            height: 35px;
            background-color: #f6f7ff;
            border-radius: 3px;
            border: solid 0.5px rgba(5, 5, 82, 0.1);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;


            >svg {
                height: 16px;
                width: 16px;
            }
        }

    }

    &__tabs {
        margin-bottom: 16px;

        button:first-of-type {
            margin-left: 24px;
        }
    }

    &__content {
        &__container {
        }

        &__header {
            padding: 0px 16px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 24px 0px;
            margin-bottom: 20px;

            &__status {
                display: flex;
                flex-direction: row;
                align-items: center;
                flex-wrap: wrap;
                gap: 24px 48px;

                >p {
                    margin: 0;
                    @include base_font_and_inputs();
                    color: rgba(5, 5, 82, 0.8);

                    >span {
                        font-weight: 500;
                        color: rgba(5, 5, 82, 0.6);
                        margin-left: 8px !important;
                    }
                }

            }

            &__actions {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 16px;

                &__button {
                    padding: 8px 12px !important;
                }

                &__menu {
                    &__options {
                        >div {
                            @include select_dropdown_item();
                            padding: 12px 20px;
                        }
                    }
                }
            }
        }

        &__body {
            padding: 0px 16px;
        }
    }

    &__footer {
        &__container {
            background-color: white;
            box-shadow: 0 -5px 15px 0 rgba(5, 5, 82, 0.05);
            border-top: solid 0.5px rgba(5, 5, 82, 0.1);
            border-radius: 6px;
            width: 100%;
            min-height: 100px;
            display: flex;
            flex-direction: column;
            padding: 16px 24px;
            margin-top: 24px;
        }

        &__navigation {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            >p {
                margin: 0;
                font-size: 14px;
                font-weight: 600;
                color: rgba(5, 5, 82, 0.8);
                padding-right: 16px;
                border-right: 0.5px solid rgba(5, 5, 82, 0.2);
            }

            &__buttons {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                padding-left: 16px;

                >p {
                    margin: 0px 12px;
                    font-size: 12px;
                    font-weight: normal;
                    color: rgba(5, 5, 82, 0.8);
                }

                >button {
                    background-color: rgba(5, 5, 82, 0.6) !important;
                    padding: 8px !important;

                    >svg {
                        >path {
                            fill: white !important;
                        }
                    }

                    &:last-child {
                        >svg {
                            transform: rotate(180deg);
                        }
                    }

                    &.disabled {
                        opacity: 1 !important;
                    }
                }
            }
        }

        &__review {

            &__container {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                gap: 24px 16px;
            }

            &__decision {
                flex: 0 0 auto;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-end;

                >p {
                    font-size: 10px;
                    font-weight: bold;
                    letter-spacing: 0.5px;
                    white-space: nowrap;
                    color: rgba(5, 5, 82, 0.4);
                    text-transform: uppercase;
                }

                &__button {
                    margin-left: 16px;
                    padding: 8px 16px !important;
                    border-radius: 100px !important;
                }
            }
        }
    }
}


.content_error {
   padding: 20px;
   text-align: center;
   color: #dd4a46;
}

.fetch-data-error {
    padding: 20px;
    text-align: center;
    color: #dd4a46;
  
    &__message {
      font-size: 16px;
      margin-bottom: 15px;
      font-weight: 600;
    }
  
    &__retry-button {
      padding: 5px 10px;
      color: rgba(5, 5, 82, 0.4);
      border: none;
      border-radius: 4px;
      font-size: 14px;
      cursor: pointer;
      transition: background-color 0.3s ease;
    }
  }
  