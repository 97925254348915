#video_player {
    &__container {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
        z-index: 1000;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: rgba(1, 1, 28, 0.9);
    }
    &__player {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 70%;
        width: 80%;
    }
    &__controls {
        margin-top: 16px;
        background-color: white;
        padding: 16px;
        width: 72%;
        border-radius: 4px;
    }
}