@import "../../../App.scss";

$filter_count_border_color: rgba(5, 5, 82, 0.2);
$filter_count_hover_box_shadow_color: rgba(85, 78, 241, 0.1);
$table_actions_item_color: rgba(5, 5, 82, 0.8);
$table_actions_item_hover_bg_color: rgba(85, 78, 241, 0.1);
$table_actions_item_div_border_color: rgba(5, 5, 82, 0.6);
$table_actions_item_div_active_bg_color: rgba(5, 5, 82, 0.6);
$element_count_color: rgba(5, 5, 82, 0.8);

@mixin application_status_tags {
  font-size: 12px;
  margin: 2px;
  padding: 0.5em 1.75em;
  border-radius: 20px;
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.animated-loading-background {
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f3f5f7;
  background: linear-gradient(to right, #f3f5f7 8%, #e5e5e5 18%, #f3f5f7 33%);
  background-size: 800px 104px;
  height: 96px;
  position: relative;
}

.table-pagination-container {
  margin: 24px 0;
}

#audit {
  &__data_container {
    margin-top: 24px;
    position: relative;
  }
  &__header_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  &__filter_container {
    display: flex;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
  }
  &__filter_count {
    display: flex;
    flex-direction: row;
    border-right: 0.5px solid $filter_count_border_color;
    padding-right: 15px;
    &_refresh {
      @include audit_button();
      margin-right: 8px;
      margin-left: 8px;
      > img {
        width: 16px;
        height: 16px;
      }
    }
  }

  &__actions_container {
    position: relative;
    align-self: flex-end;
    margin-left: auto;
    display: flex;
    &_button {
      @include audit_button();
      @include base_font_and_inputs();
      font-weight: 500;
      margin-left: auto;
      white-space: nowrap;
      > img:first-child {
        width: 16px;
        height: 16px;
      }
      > span {
        margin: 0px 10px;
      }
    }
    &_dropdown {
      @include base_font_and_inputs();
      font-weight: 500;
      padding: 8px 0px;
      display: flex;
      flex-direction: column;
      .table_action_item {
        position: relative;
        display: flex;
        align-items: center;
        align-self: center;
        width: 100%;
        padding: 12px 16px;
        color: $table_actions_item_color;
        cursor: pointer;
        white-space: nowrap;
        > img {
          width: 16px;
          height: 16px;
          margin-right: 8px;
        }
        &:hover {
          background-color: $table_actions_item_hover_bg_color;
        }
      }
    }
    &_table_columns {
      padding: 8px 0px;
      max-height: 20em;
      overflow: auto;
      &_checkbox {
        &_container {
          display: flex;
          flex-direction: column;
        }
        &_item {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 12px 16px;
          font-size: 13px;
          font-weight: normal;
          color: $table_actions_item_color;
          white-space: nowrap;
          cursor: pointer;
          &:hover {
            background-color: $table_actions_item_hover_bg_color;
          }
          > img {
            width: 0.9em;
            height: 0.9em;
            margin-left: auto;
            filter: grayscale(100%);
          }
          > span {
            margin-right: 15px;
          }
          > div {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            margin: 0px;
            margin-right: 12px;
            border-radius: 20%;
            border: solid 1px $table_actions_item_div_border_color;
            width: 1.2em;
            height: 1.2em;
            > img {
              display: none;
              width: 0.7em;
              height: 0.7em;
            }
            &.active {
              > img {
                display: inline;
              }
              background-color: $table_actions_item_div_active_bg_color;
              border: solid 1px transparent;
            }
          }
        }
      }
    }
    &_table_download {
      display: none;
      text-decoration: none;
    }
  }

  &__element_count {
    @include base_font_and_inputs();
    padding: 9px 0;
    margin: 0;
    color: $element_count_color;
    font-weight: normal;
  }

  &__circular_loading {
    display: flex;
    width: 100% !important;
    justify-content: center;
    margin-top: 15rem;
  }
}

#audit__searchBar {
  &__container {
    display: flex;
    height: 2.25em;
    width: 14em;
    border-radius: 6px;
    border: solid 0.5px $grey_border;
    background-color: $border_grey;
    box-shadow: none;
  }

  &__search_icon {
    color: $hyperblue;
    width: 20px;
    padding: 2px;
    margin-left: 8px;
  }
  &__input {
    font-size: 12px;
    color: $place_holder_text;
    vertical-align: middle;
    margin-right: 4px;
  }
}

#application__data_table {
  &__container {
    margin-top: 1em;
    box-shadow: none;
  }

  &__table {
    margin-top: 1em;
    thead {
      height: 40px;
      text-transform: uppercase;
      th {
        background-color: #f2f2f2 !important;
        padding: 8px 20px;
        @include secondary_text();
      }
    }
    tbody tr {
      cursor: pointer !important;
      td {
        @include base_font_and_inputs();
      }
    }
  }

  &__head_cell {
    padding: 1.3em;
    color: $hyperblue;
    font-weight: bold;
    text-align: left;
  }

  &__row_cell {
    position: relative;
    color: rgba(0, 3, 77, 0.8);
    padding: 1.3em;
    width: fit-content;
  }

  &__transaction_id {
    &:hover {
      cursor: pointer;
    }
  }

  &__row {
    &:hover {
      position: relative;
      background-color: $select_blue;
      cursor: pointer;

      #application__data_table__transaction_id {
        color: #000aff;
        text-decoration: underline;
      }
    }
  }
}

#application__empty_state {
  &__container {
    margin: 1.5em 0 3.5em 0;
    height: 60vh;
    background-color: $border_grey;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 6px;
  }

  &__info_text {
    @include sub_heading();
    padding-top: 2.85em;
    margin: 0;
    color: rgba(0, 3, 77, 0.8);
  }
  &__loading-spinner {
    width: 50px;
    height: 50px;
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: 50px solid #383636; /* Black */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
  }

  &__demo_text {
    padding-top: 0.5em;
    margin: 0;
    font-family: $font_family;
    font-size: 16px;
    font-weight: 600;
    color: $clickable_blue;
    text-decoration: none;
    cursor: pointer;
    display: flex;
  }

  &__demo_arrow {
    margin-left: 6px;
  }
}

#application__preview {
  &__container {
    display: flex;
    position: absolute;
    width: min-content;
    border-radius: 6px;
    box-shadow: 0 0 15px 0 rgba(5, 5, 82, 0.05);
    border: 0.5px solid $grey_border;
    background-color: $border_grey;
    z-index: 1000;
    margin-left: 10em;
    transform: translateY(-50%);
  }
  &__container_down {
    display: flex;
    position: absolute;
    width: min-content;
    border-radius: 6px;
    box-shadow: 0 0 15px 0 rgba(5, 5, 82, 0.05);
    border: 0.5px solid $grey_border;
    background-color: $border_grey;
    z-index: 500;
    margin-left: 10em;
    transform: translateY(-80%);
  }

  &__loading_container {
    width: 28.5em;
    padding: 2em;
  }

  &__loaded_container {
    padding: 1em 2em;
  }

  &__line {
    border-radius: 20px;
    height: 1em;
    margin-bottom: 1.5em;
    @extend .animated-loading-background;
  }

  &__box {
    border-radius: 6px;
    height: 10em;
    @extend .animated-loading-background;
  }

  &__details {
    display: flex;
    flex-wrap: wrap;
    padding-top: 0.5em;
  }

  &__detail_right {
    margin-right: 0.75em;
    display: flex;
  }

  &__detail_left {
    display: flex;
  }

  &__title {
    @include sub_heading();
    color: #050552;
    font-weight: 600;
    margin-right: 0.5em;
    white-space: nowrap;
    padding: 0.5em 0;
    margin: 0;
    margin-right: 0.75em;
  }

  &__value {
    @include sub_heading();
    font-weight: 300;
    color: rgba(5, 5, 82, 0.8);
    white-space: nowrap;
    padding: 0.5em 0;
    margin: 0;
  }

  &__image_container {
    display: flex;
    justify-content: center;
  }

  &__selfie_container {
    padding: 1em 0;
    height: auto;
    width: auto;
    margin-right: 2em;
  }

  &__id_container {
    padding: 1em 0;
    height: auto;
    width: auto;
  }

  &__image {
    height: 168px;
    width: auto;
    border-radius: 0.5em;
  }
  &__selfie__image {
    height: 168px;
    width: auto;
    border-radius: 0.5em;
  }
}

#application__status {
  &__approved {
    font-size: 12px;
    margin: 2px;
    padding: 0.5em 1.75em;
    border-radius: 20px;
    background-color: #e3fcef;
    color: #006644;
  }

  &__declined {
    @include application_status_tags();
    background-color: #ffebe6;
    color: #bf2600;
  }

  &__review {
    @include application_status_tags();

    background-color: #ffe19b59;
    color: #9a4c24;
  }

  &__dropped {
    @include application_status_tags();
    background-color: #f4f5f7;
    color: rgba(5, 5, 82, 0.6);
  }

  &__applying {
    @include application_status_tags();
    background-color: #deebff;
    color: #0747a6;
  }
}

#pagination-container {
  button {
    color: $hyperblue;
    border-radius: 2px;
    font-size: 12px;

    :hover {
      background-color: $select_blue;
      border-radius: 2px;
    }

    :focus,
    :active {
      background-color: $hyperblue;
      color: $border_grey;
    }
  }
  .Mui-selected {
    padding: 6px 0 !important;
    background-color: $hyperblue !important;
    color: $border_grey !important;
    height: fit-content;
    width: fit-content;
  }
}

.selectDialog_container {
  width: fit-content;
  min-width: 438px;
  height: 48px;
  color: white;
  padding: 8px 8px 8px 12px;
  gap: 8px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 10%;
  margin: 0 auto;
  transform: translateY(50%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  color: #FFF;
  font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px;
  border-radius: 8px;
border: 1px solid rgba(5, 5, 82, 0.10);
background: #050552;
box-shadow: 0px 2px 10px 0px rgba(5, 5, 82, 0.20);

  >p {
    @include sub_heading();
    color: #FFF;
    font-weight: 400 !important;
    line-height: 20px;
  }
}

.clear_all_button{
  @include base_font_and_inputs();
  width: 88px !important;
  padding: 8px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  color: #FFF;
  font-weight: 500;
}

#assign_applications_button{
  font-weight: 500 !important;
  &:hover{
    cursor: pointer;
  }
}

.clear_all_button {
  width: 88px !important;
}

.clear_all_button:hover {
  cursor: pointer;
  background: rgba(255, 255, 255, 0.2);
}

.assign_applications_button {
  width: 140px;
  display: flex;
height: 32px;
padding: 8px;
justify-content: center;
align-items: center;
gap: 8px;
  background: rgba(85, 78, 241, 1);
}

.assign_applications_button:hover {
  cursor: pointer;
  background: rgba(255, 255, 255, 0.2);
}

.toast_container {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 15%;
}

@media (max-width: 768px) {
  .selectDialog_container {
    min-width: 80%;
    max-width: 90%;
    padding: 8px 10px;
    font-size: 12px;
  }

  .clear_all_button, .assign_applications_button {
    width: 110px;
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .selectDialog_container {
    flex-direction: column;
    align-items: center;
    height: auto;
    bottom: 20%;
    transform: translateY(0);
    padding: 10px;
  }

  .clear_all_button, .assign_applications_button {
    width: 100%;
    margin-top: 8px;
    justify-content: center;
    align-items: center;
  }
}

.selcted_assignee_message_container {
  display: flex;
  justify-content: center;
  gap: 5px;
  width: 100%;
  
  strong {
    font-weight: 600;
  }
}

.divider-line {
  height: 100%;
  border: 1px solid #FFFFFF33;
}
