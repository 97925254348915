.shimmer {
    display: inline-block;
    animation: shimmer 1.5s infinite linear, beat 2s infinite ease-in-out;
    position: relative;
    transform-origin: center;
  
    @keyframes shimmer {
      0% {
        background-position: 200% 0;
      }
      100% {
        background-position: -200% 0;
      }
    }
  
    @keyframes beat {
      0%, 100% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.05); // Slightly larger scale for a noticeable beat
      }
    }
  }

#faq_right_container__shimmer {
    border-bottom: 0.5px solid rgba(23, 23, 167, 0.05);
}
  