@import "../../../App.scss";

$slideshow_background_color: rgba(1, 1, 28, 0.9);
$slidshow_image_header_color:rgba(5, 5, 82, 0.8);
$slideshow_control_color: $slidshow_image_header_color;
$slideshow_control_disabled_color: rgba(5, 5, 82, 0.4);
$slideshow_control_disabled_image_opacity: 0.4;
$slideshow_control_line_color: rgba(5, 5, 82, 0.2);
$slideshow_control_active_bg_color: rgba(5, 5, 82, 0.1);
$carousal_empty_color: $slidshow_image_header_color;
$carousal_tag_color: rgba(5, 5, 82, 0.6); 
$common_image_box_shadow: 0 0 4px 0 rgba(0, 0, 0, 0.08);

.slideshow {
    &__container {
        width: 100vw;
        height: 100vh;
        overflow: auto;
        padding: 40px !important;
        padding-bottom: 70px !important;
        position: fixed;
        top: 0;
        left: 0;
        background-color: $slideshow_background_color;
        z-index: 99999;
        display: flex;
        flex-direction: column;
        gap: 5%;
    }

    &__image {
        &__container {
            height: 85%;
            position: relative;
        }

        &__header {
            z-index: 99999;
            top: 16px;
            left: 50%;
            transform: translate(-50%, 0%);
            position: absolute;
            border-radius: 3px;
            box-shadow: $common_image_box_shadow;
            background-color: #fff;
            padding: 3px 12px;
            font-size: 14px;
            color: $slidshow_image_header_color;
            font-weight: 400;
            pointer-events:none
        }

        &__scale {
            height: 100%;
            width: auto;
            max-height: 100%;
            display: flex;
            flex-direction: column;
            justify-items: center;
            align-items: center;
            transform-origin: top;

            >img, canvas {
                width: auto;
                height: 100%;
                object-fit: contain;
            }
            .common_tiff_loader > .common_spinner {
                border-top-color: white;
            }
        }

        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        overflow: auto;
    }

    &__control {
        z-index: 99999;
        
        &__container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        box-shadow: 0 0 15px 0 rgba(5, 5, 82, 0.15);
        border: solid 0.5px rgba(5, 5, 82, 0.1);
        background-color: #fff;
        border-radius: 6px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 4px;
        border-radius: 10px;

        >div {
            display: flex;
            padding: 12px;
            cursor: pointer;
            color: $slideshow_control_color;

            &.line {
                border-left: 0.5px solid $slideshow_control_line_color;
                height: 80%;
                padding: 0;
                margin: 0px 2px;
                // display: block;
                pointer-events: none;
            }
            &.disabled {
                color: $slideshow_control_disabled_color;
                cursor: not-allowed;
                > img {
                   opacity: $slideshow_control_disabled_image_opacity; 
                }
            }

            &.active,
            &:hover:not(.disabled) {
                border-radius: 6px;
                background-color: $slideshow_control_active_bg_color;
            }
        }
    }
}

.common_carousal {
    &__container {
        height: 240px;
        position: relative;
        scroll-behavior: smooth;

        &.empty {
            @include base_font_and_inputs();
            height: unset;
            display: flex;
            flex-direction: column;
            justify-content: center;
            color: $carousal_empty_color;
        }

        .right {
            position: absolute;
            z-index: 1000;
            right: 16px;
            top: 50%;
            background-color: #fff;
            padding: 14px 17px;
            border-radius: 50%;
            transform: translate(-0%, -50%);
            cursor: pointer;
        }

        .left {
            position: absolute;
            z-index: 1000;
            left: 16px;
            top: 50%;
            background-color: #fff;
            padding: 14px 17px;
            border-radius: 50%;
            transform: translate(-0%, -50%);
            cursor: pointer;
        }
    }

    &__images {
        display: flex;
        flex-direction: row;
        gap: 0px 8px;
        overflow: hidden;
        height: 100%;
        max-height: 100%;
    }

    &__image {
        position: relative;
        cursor: pointer;
        height: 100%;

        >img {
            border-radius: 6px;
            width: auto;
            height: 100%;
        }

        >span {
            @include secondary_text();
            position: absolute;
            left: 8px;
            bottom: 8px;
            border-radius: 3px;
            box-shadow: $common_image_box_shadow;
            background-color: #fff;
            padding: 3px 12px;
            color: $carousal_tag_color;
        }
    }

    &__video {
        position: relative;
        cursor: pointer;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        &_play_button {
            position: absolute;
            background-color: white;
            padding: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.2);
        }

        >span {
            @include secondary_text();
            position: absolute;
            left: 8px;
            bottom: 8px;
            border-radius: 3px;
            box-shadow: $common_image_box_shadow;
            background-color: #fff;
            padding: 3px 12px;
            color: $carousal_tag_color;
        }
    }

    &__pdf {
        // display: flex;
        // flex-direction: row;
        // gap: 0px 8px;
        // overflow: hidden;
        height: 100%;
        max-height: 100%;
    }
}

.common_zoom {
    &__container {
        height: 240px;
        overflow: hidden;
        border-radius: 6px;
        // Hack for safari - creates new browser context enabling the border-radius property
        isolation: isolate;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        > img, canvas {
          height: 100%;
          width: auto;
          transition: transform 0.1s ease-out;
        }
    }
}

.common_tiff_loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}