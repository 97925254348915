@import "../../../App.scss";

$summary_header_color: #050552;
$summary_details_label_color: rgba(5, 5, 82, 0.6);
$summary_details_value_color: rgba(5, 5, 82, 0.6);

@mixin summaryCommonFlex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

#summary {
  &__container {
    background-color: $border_grey;
    border-radius: 6px;
    border: solid 0.5px rgba(5, 5, 82, 0.1);
    color: $hyperblue;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 17px;
    gap: 8px;
    box-shadow: 0px 0px 15px 0px rgba(85, 78, 241, 0.05);
  }
  &__header {
    &__container {
      display: flex;
      padding: 16px;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;
      border-radius: 6px;
      border: 1px solid rgba(5, 5, 82, 0.1);
      background: #fff;
      box-shadow: 0px 0px 10px 0px rgba(5, 5, 82, 0.05);
      > span {
        color: rgba(5, 5, 82, 0.8);

        /* Heading-Body 1 */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
      }
      h1 {
        @include heading_inside_modal_bold();
        margin: 0px;
        color: $summary_header_color;
      }
    }
    &__details {
      @include summaryCommonFlex();
      gap: 12px 32px;
      > p {
        margin: 0px;
        @include base_font_and_inputs();
        text-align: left;
        color: $summary_details_label_color;
        > span {
          margin-left: 4px;
          font-weight: 500;
          color: $summary_details_value_color;
        }
      }
    }
  }
  &__body {
    &_container {
      display: flex;
      padding: 16px;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;
      border-radius: 6px;
      border: 1px solid rgba(5, 5, 82, 0.1);
      background: #fff;
      box-shadow: 0px 0px 10px 0px rgba(5, 5, 82, 0.05);

      > span {
        color: rgba(5, 5, 82, 0.8);

        /* Heading-Body 1 */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
      }
    }
  }
  &__image {
    &_container {
      width: 100%;
      display: flex;
      gap: 20px;
      flex-direction: column;
    }
    &_header {
      @include overline_heading();
      color: rgba(5, 5, 82, 0.6);

      /* OVERLINE heading */
      font-family: Inter;
      font-size: 11px;
      font-style: normal;
      font-weight: 600;
      line-height: 14px; /* 127.273% */
      letter-spacing: 0.22px;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      cursor: pointer;
      > img {
        margin-left: 4px;
        transform: rotate(270deg);
      }
      &.active {
        > img {
          transform: rotate(360deg);
        }
      }
    }
    &_carousal {
      &.empty {
        @include base_font_and_inputs();
      }
    }
  }
  &__metadata {
    &__header {
      display: flex;
      align-items: center;
      @include overline_heading();
      cursor: pointer;
      > img {
        margin-left: 4px;
        transform: rotate(270deg);
      }
      &.active {
        > img {
          transform: rotate(360deg);
        }
      }
    }
    &__details {
      gap: 16px 24px;
      @include summaryCommonFlex();
      > p {
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 0px;
        @include base_font_and_inputs();
        color: $summary_details_label_color;
        > span {
          margin-left: 4px;
          font-weight: 500;
          color: $summary_details_value_color;
        }
      }
    }
  }
}
