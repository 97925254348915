
@import "../../../App.scss";

%legends {
    height: 12px;
    width: 12px;
    border-radius: 3px;
    margin-right: 8px;
  }  

.demo_modal {
  &__creds {
    margin: 20px 0px 20px 0px;
  }
  &__cred_wrapper {
    width: 100%;
  }
}

#demo_modal {

    &__heading {
        color : $hyperblue;
        font-size: 20px;
        font-weight: 600;
    }

    &__cancel_icon {
        margin-top: 16px;
        cursor: pointer;
    }

    &__web_tab, &__mobile_tab {
        text-transform: capitalize;
        font-weight: 600;
        color: $grey_border;
        font-family: $font_family;
        &:hover, &:focus {
            color : $clickable_blue;
        }
    }

    &__web_tab--selected, &__mobile_tab--selected {
        text-transform: capitalize;
        color: $clickable_blue;
        font-weight: 600;
        font-family: $font_family;
    }


    &__container {
        background-color:$border_grey;
        border-radius: 6px;
        width: 35em;
        height: 35em;
        padding: 1.4em;
        color: $hyperblue;
    }

    &__web_description_1, &__mobile_description_1 {
        font-size: $subheading_font_size;
        font-weight: 300;
        margin-top: 20px;
    }

    &__web_description_2, &__mobile_description_2 {
        font-size: $subheading_font_size;
        font-weight: 300;
        margin-top: 19px;
    }

    &__link {
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: $subheading_font_size;
        max-height: 1.6em;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        width : 95%;
        padding-top: 5px;
        word-break: break-all;
        color:$clickable_blue;
    }
    &__link_mobile {
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: $subheading_font_size;
      max-height: 1.6em;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      width : 95%;
      padding-top: 5px;
      word-break: break-all;
      color:$clickable_blue;
    }

    &__new_link_button, &__email_button {
        background-color: $background_blue;
        font-size: 10px;
        border-radius: 6px;
        text-transform: 'none';
        color: $clickable_blue;
        font-weight: 'normal';
        text-transform: none;
    }

    &__email_sent_button {
        background-color: $background_blue;
        color: $success_green;
        font-size: 10px;
        border-radius: 6px;
        text-transform: 'none';
        font-weight: 'normal';
        text-transform: none;
        cursor: not-allowed;
    }

    &__new_link_button {
        width: 90%;
    }

    &__email_button, &__email_sent_button {
        .tooltiptext {
            visibility: hidden;
            width: 120px;
            background-color: #3c4256;
            color: #fff;
            text-align: center;
            border-radius: 6px;
            margin-top: 4px;
            padding: 4px 8px 5px;
            text-transform: lowercase;
            
            /* Position the tooltip */
            position: absolute;
            z-index: 1;
            top: 100%;
            left: 50%;
            margin-left: -60px;
        }
    }

    &__email_button:hover
    {
        .tooltiptext {
            visibility: visible;
        }
    } 

    &__workflow_select {
      &_container{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 10px 0px 10px 0px;
        > p {
          margin: 0;
          font-size: 12px;
          font-weight: 300;
          font-stretch: normal;
          white-space: nowrap;
        }
      }
    }
}

#analytics {
    &__container {
      margin-top: 3em;
      width: 100%;
    }

    &__heading_container {
      display: flex;
      align-items: center;
    }

    &__time_dropdown {
      margin-left: 1em;
    }

    &__user_processed_container {
      margin-top: 2em;
      margin-bottom: 2em;
      padding: 2em 2em 0 2em;
      border-radius: 6px;
      box-shadow: 0 0 15px 0 rgba(239, 239, 239, 0.25);
      border: solid 0.5px #dbdbdb;
      background-color: $border_grey;
      flex-direction: row;
      width: 100%;
    }

    &__user_processed_sub_heading {
      @extend .font-design-sub-heading;
      font-weight: normal;
      color: rgba(0, 3, 77, 0.8);
      margin-bottom: 2em;
    }

    &__user_processed_plots {
      margin-top: 1.5em;
    }

    &__iframe_plot1 {
      height: 100%;
      border: none;
      width: 100%;
    }

    &__download_p1 {
      height: 40px;
      width: 100%;
      position: relative;
      top: -50px;
      background: white;
    }

    &__download_p2 {
      height: 40px;
      width: 100%;
      position: relative;
      top: -45px;
      background: white;
    }

    &__iframe_container {
      height: 100%;
    }

    &__iframe_plot2{
      height: 23em;
      width: 100%;
      border: none;
    }

    &__pie_chart_container {
      flex-direction: row;
      width: 100%;
      margin-bottom: 4.25em;
    }

    &__pie_chart_card_container {
      width: 95%;
      box-shadow: 0 0 15px 0 rgba(239, 239, 239, 0.25);
      border: solid 0.5px $grey_border2;
      background-color: $border_grey;
      padding: 1em 1.5em 1.5em 1.5em;
    }

    &__pie_chart_main_text {
      @extend .font-design-sub-heading;
      font-weight: normal;
      color: rgba(0, 3, 77, 0.8);
      padding-top: 8px;
    }

    &__pie_chart_sub_text {
      font-weight: normal;
      font-size: 12px;
      font-family: $font_family;
      color: rgba(0, 3, 77, 0.7);
      margin-bottom: 0.5em;
    }

    &__spacer {
      width: 100%;
      height: 0.5em;
    }

    &__iframe_container {
      width: fit-content;
      margin: auto;
    }

    &__iframe_plot3, &__iframe_plot4{
      height: 15em;
      width: 100%;
      border: none;
      margin-top: 2.5em;
    }

    &__legends {
      display: flex;
      justify-content: space-evenly;
      margin-top: -45px;
    }

    &__legend_container {
      display: flex
    }

    &__plot3_legend1 {
      @extend %legends;
      background-color: #f9d45c;;
    }

    &__plot3_legend2 {
      @extend %legends;
      background-color: #88bf4d;
    }

    &__plot4_legend1 {
      @extend %legends;
      background-color: #ef8c8c;;
    }

    &__plot4_legend2 {
      @extend %legends;
      background-color: #88bf4d;;
    }

    &__plot4_legend3 {
      @extend %legends;
      background-color: #509ee3;
    }

    &__empty_state {
      margin: 2em 0;
      padding: 3em 0 2.5em 0;
      border-radius: 6px;
      box-shadow: 0 0 15px 0 rgba(239, 239, 239, 0.25);
      border: solid 0.5px $grey_border2;
      background-color: $border_grey;
      flex-direction: column;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        margin-top: 2.5em;
        font-family: $font_family;
        color: rgba(5, 5, 82, 0.8);
        font-size: $subheading_font_size;
      }
    }
  }

#vkyc_card {
  width: 95%;
  padding: 2em 1em;
  border-radius: 6px;
  box-shadow: 0 0 15px 0 rgba(239, 239, 239, 0.25);
  border: solid 0.5px #dbdbdb;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  &__icon_container {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &__main_heading {
    font-size: 18px;
    font-weight: normal;
    text-align: left;
    color: rgba(5, 5, 82, 0.8);;
    margin: 0;
  }

  &__sub_heading {
    font-size: $subheading_font_size;
    font-weight: 300;
    line-height: 1.75;
    text-align: left;
    color: rgba(5, 5, 82, 0.8);
    margin: 16px 0 0 0;
  }

  &__action_text_container {
    margin-top: 1em;
    width: 95%;
    display: flex;
    justify-content: flex-end;
  }

  &__action_text {
    font-size: 16px;
    font-weight: 600;
    text-align: right;
    color: $clickable_blue;
    text-decoration: none;
    display: flex;
    cursor: pointer;
  }
}
  
