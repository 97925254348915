@import "../../../App.scss";

$common_card_header_title_text_color: rgba(5, 5, 82, 0.6);
$common_card_radio_item_text_color: rgba(5, 5, 82);
$common_card_radio_item_border_color: rgba(5, 5, 82, 0.6);
$common_card_radio_item_border_color_active: #554ef1;
$common_card_header_title_line_spacing: 1.43;

.common_card {
  &_container {
    @include sub_heading();
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 24px;
    max-width: 600px;
    min-width: 300px;
  }
  &_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    &_title {
      @include base_font_and_inputs();
      color: $common_card_header_title_text_color;
      margin-left: 16px;
      white-space: wrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: $common_card_header_title_line_spacing;
    }
    &_back_button {
      padding-left: 0px;
      cursor: pointer;
    }
  }
  &_radio {
    &_container {
      display: flex;
      flex-direction: column;
    }
    &_item {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 16px 0px 12px 0px;
      @include base_font_and_inputs();
      color: $common_card_radio_item_text_color;
      > div {
        cursor: pointer;
        display: inline;
        margin: 0px;
        margin-right: 16px;
        border-radius: 50%;
        border: solid 1px $common_card_radio_item_border_color;
        width: 1em;
        height: 1em;
        &.active {
          border: 0.3em solid $common_card_radio_item_border_color_active !important;
        }
      }
    }
  }
  &_filter {
    margin: 0px;
    margin-left: 30px;
  }
  &_submit {
    &_container {
      margin-top: 20px;
    }
    &_button {
      @include audit_button();
      background-color: #554ef1;
      font-weight: 600;
      color: white;
      padding: 8px 24px;
      &.disabled {
        cursor: not-allowed;
        opacity: 1;
      }
      &:hover:not(.disabled) {
        cursor: pointer;
        color: white;
      }
    }
  }
}
