@import "../../../../App.scss";

$comment_heading_color: rgba(5, 5, 82, 0.4);
$comment_placeholder_color: rgba(5, 5, 82, 0.3);
$comment_color: rgba(5, 5, 82, 0.8);
$comment_section_border: solid 0.5px rgba(5, 5, 82, 0.2);
$disable_button_color: rgba(5, 5, 82, 0.2);

%tooltip-text {
  border-radius: 6px;
  border: 1px solid rgba(5, 5, 82, 0.10);
  background: #01011C;
  font-size: 12px;
  font-family: $font_family;
  color: #fff;
  display: block;
  width: 292px;
  padding: 8px;
  position: absolute;
  z-index: 1;
  bottom: 50px;
}

#comment {
  &_body {
    &_container {
      background-color: $border_grey;
      color: $hyperblue;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    &_top_panel {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
    &_heading {
      margin: 0;
      color: $comment_heading_color;
      @include overline_heading();
      text-align: left;
    }
    &_view {
      margin: 0;
      @include secondary_text();
      font-weight: 400;
      color: #554ef1;
      cursor: pointer;
    }
  }

  &_text {
    &_textbox {
      min-height: 4em;
      margin-top: 1.4em;
      padding: 1em;
      font-size: 12px;
      border-radius: 6px;
      border: $comment_section_border;
      background-color: rgba(85, 78, 241, 0);
      color: $comment_color;
      font-family: $font_family;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      > textarea {
        width: 100%;
        border: none;
        outline-width: 0;
        font-family: $font_family;
        resize: none;
        height: 4em;
        &:focus {
          outline: none;
        }
        &::placeholder {
          color: $comment_placeholder_color;
        }
        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: $comment_placeholder_color;
        }

        &::-ms-input-placeholder {
          /* Microsoft Edge */
          color: $comment_placeholder_color;
        }
      }
      > button {
        display: flex;
        align-self: flex-end;
        cursor: not-allowed;
      }
    }
  }

  &_list {
    &_container {
      width: 100%;
    }
    &_heading {
      @include heading_inside_modal_bold();
      color: #050552;
      cursor: pointer;
      display: flex;
      align-items: center;

      > p {
        margin: 0;
        margin-left: 12px;
      }
    }
    &_box {
      width: 100%;
      max-height: 12em;
      margin-top: 1em;
      border-top: solid 0.5px rgba(5, 5, 82, 0.1);
      border-bottom: solid 0.5px rgba(5, 5, 82, 0.1);
      overflow: scroll;
      display: flex;
      flex-direction: column-reverse;
    }
  }

  &_item {
    &_container {
      margin-top: 1em;
    }
    &_creater_info {
      font-size: 10px;
      font-weight: 500;
      color: rgba(5, 5, 82, 0.6);
      line-height: 1.8;
      margin: 0;
    }
    &_message {
      border-radius: 6px;
      padding: 12px;
      border: $comment_section_border;
      background-color: rgba(85, 78, 241, 0.01);
      @include base_font_and_inputs();
      color: $comment_color;
      flex-grow: 0;
      word-wrap: break-word;
    }
  }
}

#review {
  &_buttons {
    &_container {
      margin-top: 1.5em;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      gap: 10px;

      &.loading {
        justify-content: center;
      }
    }
    &_button {

      &_decline,
      &_approve,
      &_disable {
        padding: 12px;
        border-radius: 6px;
        border: $comment_section_border;
        background-color: #fff;
        cursor: pointer;
        display: flex;
        align-items: center;
        position: relative;

        >p {
          @include heading_inside_modal_semi_bold();
          padding-left: 0.5em;
          color: $comment_color;
          margin: 0;
          cursor: pointer;
        }
        > span {
          display: none;
        }
      }
      &_disable {
        cursor: not-allowed;
        >p {
          color: $disable_button_color;
          cursor: not-allowed;
        }
        path {
          stroke: $disable_button_color !important;
        }

        &:hover {
          >span {
            @extend %tooltip-text
          }
        }
      }
      &_decline {
        &:hover {
          border: solid 0.5px #bf2600 !important;
           > p {
            color: #bf2600 !important;
           }
           path {
            stroke: #bf2600 !important;
           }
        }
      }
      &_approve {
        &:hover {
          border: solid 0.5px #064 !important;
          > p {
            color: #006644 !important;
          }
          path {
            stroke: #006644 !important;
          }
        }
      }
    }
  }
}

.focus {
  outline: none;
  border: solid 2px rgba(85, 78, 241, 0.5) !important;

  > button {
    cursor: pointer !important;
  }
}

.right {
  text-align: right;
}

.left-disable-button {
  left: 0
}

.right-disable-button {
  right: 0;
}
