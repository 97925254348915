@import "../../../App.scss";

$appid_toggle_bg_color: rgb(253, 253, 253);
$appid_toggle_border_color: rgba(5, 5, 82, 0.1);
$appid_toggle_button_color: rgba(5, 5, 82, 0.4);
$appid_toggle_button_active_box_shadow_color: rgba(85, 78, 241, 0.1);
$appid_toggle_button_active_color: #554ef1;

.credentials_select_wrapper {
    &__container {
        display: flex;
        flex-direction: row;
        gap: 16px;
    }
}

.appid__toggle {
    &_container {
        @include base_font_and_inputs();
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        background-color: $appid_toggle_bg_color;
        border: solid 0.5px $appid_toggle_border_color; 
        border-radius: 100px;
        padding: 2px 2px;
        > button {
            flex: 1 1 50%;
            padding: 5.5px 15px;
            color: $appid_toggle_button_color;
            cursor: pointer;
            &.active {
                background-color: white !important; 
                border-radius: 100px;
                box-shadow: 0 0 15px 0 $appid_toggle_button_active_box_shadow_color;
                color: $appid_toggle_button_active_color;
                transition: 0.2s;
            }
            &.disabled {
                cursor: not-allowed; 
            }
        }
    }
}