@import "../../../App.scss";

$custom_tab_color: rgba(5, 5, 82, 0.6);
$custom_tab_active_color: #554ef1;
$horizontal_line_border_color: rgba(5, 5, 82, 0.1);

.hor_line {
    width: 100%;
    border-bottom: 0.5px solid $horizontal_line_border_color;
}

.custom_tabs {
    min-height: unset;
    border-bottom: 0.5px solid #ebeef1;

    button {
        @include secondary_text();
        color: $custom_tab_color !important;
        margin-right: 30px !important;
        text-transform: none !important;
        padding: 0px;
        min-height: unset;
        padding-bottom: 9px;
    }

    button.tabActive {
        color: $custom_tab_active_color !important;
        font-weight: 600 !important;
        @include secondary_text();
    }

    span.MuiTabs-indicator {
        background-color: $custom_tab_active_color !important;
        height: 1px !important;
    }
}