#chevron {
  &_icon {
    width: 8px;
    height: 8px;
    background: #d3d3d3;
    clip-path: path('M1.2 0.8 Q0.8 0.8 0.8 1.2 L0.8 6.8 Q0.8 7.2 1.2 7.2 L6.8 4.4 Q7.2 4 6.8 3.6 L1.2 0.8');
    margin-right: 8px;

    &.expanded {
        transform: rotate(90deg);
        background-color: rgba(5,5,82,0.8);
    }
  }
}
