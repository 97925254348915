@import "../../../../../../App.scss";


.generic {
  &_card {
    &_v2 {
      &_panel {
          overflow-y: scroll;
          scrollbar-width: thin;
          scrollbar-color: rgba(4, 4, 73, 0.409) rgba(4, 4, 73, 0);

          &::-webkit-scrollbar {
            width: 1px;
            color: rgba(4, 4, 73, 0.409);
          }
      }
      &__video {
        &_player {
          &_container {
            width: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
          }
          &__controls {
            border: 1px solid rgba(5, 5, 82, 0.1);
            border-radius: 4px;
            width: 100%;
            padding: 4px 8px;
            margin-top: 4px;
          }
        }
      }
      &__grid {
        display: grid;
        grid-template-columns: auto;
        grid-gap: 16px;
      }
      &__collapsible {
        &_container {
          margin-bottom: 24px;

          &.__collapsed {
            overflow: hidden;
          }
        }
        &_header {
          position: relative;
          display: flex;
          width: 100%;
          align-items: center;

          &.__collapsed {
            > p {
              margin-bottom: 0;
              margin-top: 0;
            }
          }
        }
        &_icon {
          height: 4px;
          transition: 0.2s ease;
          transform: rotate(270deg);
          margin-left: 4px;

          > path {
            stroke: rgba(5, 5, 82, 0.4);
          }

          &.__expanded {
            transform: rotate(360deg);
            transition: 0.2s ease;
            margin-bottom: 16px;

            > path {
              stroke: rgba(5, 5, 82, 0.4);
            }
          }
        }
        &_content {
          transition: max-height 1s ease;
          height: 0px;
          scrollbar-width: thin;
          scrollbar-color: rgba(4, 4, 73, 0.409) rgba(4, 4, 73, 0);
        
          &.__expanded {
            height: 100%;
            transition: height 1s ease;
            overflow-y: scroll;
          }

          &::-webkit-scrollbar {
            width: 1px;
            color: rgba(4, 4, 73, 0.409);
          }
        }
      }
      &__row {
        display: flex;
        flex-direction: row;
        gap: 24px;
        overflow-x: hidden;
      }
      &__title {
        @include overline_heading();
        color: rgba(5, 5, 82, 0.4);
        margin-bottom: 16px;
        margin-top: 0;
      }
      &__column {
        display: flex;
        flex-direction: column;
        gap: 20px;
        overflow-x: scroll;

        scrollbar-width: thin;
        scrollbar-color: rgba(4, 4, 73, 0.409) rgba(4, 4, 73, 0);
      }
      &__media {
        &__label {
          display: flex;
          justify-content: center;
          align-items: center;
          p {
            font-size: 10px;
            margin-bottom: 0;
            margin-top: 6px;
            text-transform: uppercase;
            letter-spacing: 1px;
            color: rgba(5, 5, 82, 0.8);
          }
        }
      }
    }
  }
}
