@import "../../../App.scss";

$common_search_border_color: rgba(5, 5, 82, 0.2);
$common_search_focus_box_shadow_color: rgba(85, 78, 241, 0.1);
$common_search_focus_border_color: rgba(85, 78, 241, 0.5);
$common_search_placeholder_color: rgba(5, 5, 82, 0.4);
$multi_select_options_border_color: rgba(5, 5, 82, 0.1);
$multi_select_options_hover_bg_color: rgba(85, 78, 241, 0.1);
$common_select_menu_item_text_color: rgba(5, 5, 82, 0.8);
$common_select_text_color: hsla(240, 89%, 17%, 0.6);

@mixin select_dropdown_item {
  @include sub_heading();
  width: 100%;
  text-align: left;
  color: $common_select_menu_item_text_color;
  padding: 8px 20px;
  white-space: nowrap;
  cursor: pointer;
  &:hover {
      background-color: rgba(85, 78, 241, 0.1)
  }
  &.disable {
    color: $common_search_placeholder_color;
    cursor: not-allowed;
  }
}

.date_dropdown {
  position: relative;
  &__button {
    @include audit_button();
    min-width: 100%; 
    justify-content: center;
    padding: 8px 15px;
   > img {
    margin-left: 5px;
   } 
  }
}

.common__searchbar {
  @include sub_heading();
  display: flex;
  flex-direction: row;
  padding: 10px;
  border-radius: 6px;
  border: solid 0.5px $common_search_border_color;
  width: fit-content;
  &.focus {
    border-radius: 6px;
    box-shadow: 0 0 15px 0 $common_search_focus_box_shadow_color;
    border: solid 2px $common_search_focus_border_color;
  }
  > img {
    cursor: pointer;
  }
  > input {
    width: 214px;
    margin-left: 7px;
    border: none;
    outline-width: 0;
    &.close {
      width: 0;
      margin: 0;
      padding: 0;
    }
    &:focus {
      outline: none;
    }
    &::placeholder {
      font-size: 13px;
      color: $common_search_placeholder_color;
    }
    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $common_search_placeholder_color;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: $common_search_placeholder_color;
    }
  }
}

.multi_select {
  &_container {
    display: flex;
    flex-direction: row;
  }
  &_search_box {
    position: relative;
    padding: 0px;
    width: 100%;
  }
  &_option_box {
    position: absolute;
    top: 100%;
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    background-color: white;
    border: solid 0.5px $multi_select_options_border_color;
    border-radius: 6px;
    box-shadow: 0 5px 15px 0 $multi_select_options_border_color;
    min-width: 100%;
    max-height: 300px;
    overflow: auto;
    z-index: 100;
    padding: 0px;
    > button {
      @include select_dropdown_item();
      @include base_font_and_inputs();
      &:hover:not(.empty) {
        cursor: pointer;
        background-color: $multi_select_options_hover_bg_color;
      }
      span {
        display: block;
      }
      &.empty {
        opacity: 0.5;
        text-align: center;
        > span {
          padding-left: 0px;
        }
      }
    }
  }
}

.common__search_select {
  @include sub_heading();
  display: flex;
  flex-direction: column;
  padding: 12px;
  border-radius: 6px;
  border: solid 0.5px $common_search_border_color;
  &_options {
    min-width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0px 8px;
    &_option {
      margin-bottom: 8px;
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px 12px;
      border: solid 0.5px $multi_select_options_border_color;
      background-color: white !important;
      border-radius: 100px;
      box-shadow: 0 0 3px 0 $common_search_focus_box_shadow_color;
      white-space: nowrap;
      color: $common_select_menu_item_text_color;
      > img {
        opacity: 0;
        cursor: pointer;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        right: 0px;
        background-color: white;
        display: flex;
        justify-content: center;
        transition: 0.25s;
      }
      &:hover {
        > img {
          opacity: 1;
          position: absolute;
          > img {
            filter: brightness(0) invert(33%) sepia(55%) saturate(7149%)
              hue-rotate(238deg) brightness(98%) contrast(93%) saturate(100%);
            height: 100%;
          }
        }
      }
    }
  }
  &.focus {
    border-radius: 6px;
    box-shadow: 0 0 15px 0 $common_search_focus_box_shadow_color;
    border: solid 2px $common_search_focus_border_color;
  }
  &_search_box {
    display: flex;
    flex-direction: row;
    > input {
      width: 100%;
      margin-left: 7px;
      border: none;
      outline-width: 0;
      &:focus {
        outline: none;
      }
      &::placeholder {
        color: $common_search_placeholder_color;
      }
      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: $common_search_placeholder_color;
      }

      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: $common_search_placeholder_color;
      }
    }
  }
}

.common_select {
  &_container {
    position: relative;
    >.disable-div {
      pointer-events: none;
      opacity: 0.4;
    }
  }
  &_text {
    display: flex;
    cursor: pointer;
    color: #554EF1;
    font-size: 24px;
    font-weight: 500;
    line-height: 28px; /* 116.667% */
    letter-spacing: 0.24px;
    &:hover {
      text-decoration: underline;
    }
    &.active {
      > img {
        transform: rotate(180deg);
      }
    }
  }
  &_button {
    @include audit_button();
    font-family: $font_inter;
    padding: 8px 15px;
    cursor: pointer;
    > img {
        margin-left: 5px;
    }
    &.active {
      > img {
        transform: rotate(180deg);
      }
    }
  }
  &_menu {
    position: absolute;
    top: 100%;
    margin-top: 10px;
    max-height: 18em;
    background-color: white;
    box-shadow: 0 5px 15px 0 rgba(5, 5, 82, 0.1);
    z-index: 1000;
    overflow-y: auto;
    padding: 0px;
    min-width: 100%; 
    > div {
       @include select_dropdown_item(); 
       font-size: 13px;
    }
    p {
      margin: 0;
      padding: 0;
    }
  }
  &_menu_item {
    display: flex;
  }
}

.search_loader_wrapper .common_spinner {
  width: 17px !important;
  height: 17px !important; 
}
.search_loader_wrapper {
  width: 17px;
  height: 17px;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.split_input_container {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  justify-content: space-between;

  > div {
    flex: 0 0 15%;
    max-width: 15%;

    &::before {
      display: none !important;
    }

    &::after {
      display: none !important;
    }
  }

  input {
    text-align: center !important;
    border-radius: 5px !important;
    border: solid 0.5px rgba(5, 5, 82, 0.5) !important;
    width: 100%;
    &:focus {
      border: solid 2px #554ef1 !important;
    }

    &::placeholder {
      text-align: center !important;
      white-space: pre !important;
      letter-spacing: 8px !important;
      color: rgba(5, 5, 82, 0.3) !important;
    }
  }
}

.common_single_select {
  &_search_container {
    margin-left: 5px;
      > div {
          width: 100%;
          padding: 5px 12px !important;
      }
      input {
          color: $common_select_text_color !important;
          font-size: 12px !important;
      }
  }

  &_item_container {
          position: absolute;
          margin-top: 8px;
          display: flex;
          flex-direction: column;
          background-color: white;
          border: solid 0.5px $multi_select_options_border_color;
          border-radius: 6px;
          box-shadow: 0 5px 15px 0 $multi_select_options_border_color;
          width: 350px;
          max-height: 300px;
          overflow: auto;
          z-index: 100;
          padding: 0px;
  }

  &_empty_container {
      display: flex;
      flex-direction: column;
      margin: 10px 0;
      &_text {
          font-size: 12px;
          color: $common_select_text_color; 
          text-align: center;
      }
  }

  &_option {
    font-size: 12px;
    color: $common_select_text_color; 
  }
}

.checkbox-container {
  width:20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  >input {
    width: 16px;
  height: 16px;
  flex-shrink: 0;
  border-radius: 24px;
  border: 1px solid rgba(5, 5, 82, 0.40);
  background: #FFF;
   }
}

.disabled {
  cursor: not-allowed;
}

