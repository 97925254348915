.reason-selection {
  display: flex;
  padding: 8px 8px 8px 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  width: 100%;
  min-height: auto;
  max-height: 240px;
  overflow-y: scroll;
  position: relative;
}

.custom-input-container {
  width: 100%;
}

.singleSelect-custom-input-container {
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 0 0;
  width: 80%;
  margin-left: 42px;
  border-bottom: 1px solid rgba(5, 5, 82, 0.8);
}

.singleSelect-custom-input-container > input {
  width: 100%;
  padding: 3px 3px 3px 2px;
  border: none;
  outline: none;
  transition: border-bottom 0.3s ease;
  color: rgba(5, 5, 82, 0.8);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.reason-option {
  display: flex;
  padding: 8px 12px;
  align-items: center;
  gap: 12px;
  align-self: stretch;

  > label {
    color: rgba(5, 5, 82, 0.8);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
  .singleSelect-input-container {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    > input {
      width: 16px;
      height: 16px;
      flex-shrink: 0;
      border-radius: 24px;
      border: 1px solid rgba(5, 5, 82, 0.4);
      background: #fff;
    }
  }

  &:hover {
    cursor: pointer;
    border-radius: 6px;
    background: rgba(5, 5, 82, 0.03);
  }
}

.alert-container {
  position: sticky;
  z-index: 25;
  display: flex;
  justify-content: center;
  width: 100%;
  bottom: -9px;
}

.custom-validation-alert {
  display: flex;
  min-width: auto;
  max-width: 100%;
  height: 36px;
  padding: 8px 4px 8px 4px;
  justify-content: space-around;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid rgba(199, 60, 3, 0.1);
  background: #fcf5f2;
  box-shadow: 0px 0px 5px 0px rgba(5, 5, 82, 0.1);

  > p {
    color: #c73c03;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }

  > button {
    color: rgba(5, 5, 82, 0.6);
    text-align: center;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    cursor: pointer;
  }
}
